import React, { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.scss'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core'
import Button from '../../atoms/Button'
import { push } from 'connected-react-router'
import { selectLoginState } from '../../../store/selector';
import SettingsStateModules from '../../../store/settingsState';
import LoginStateModules from '../../../store/loginState';

import { isOnline } from '../../../util/utils';

const useStyles = makeStyles((theme) => ({
  header: {
    alignItems: 'left',
    justifyContent: 'center',
    backgroundColor: '#000000',
    minHeight: '65px',
  },
  bar: {
    padding: '0 12px',
    minHeight: '45px'
  }
}))


const Header = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  // settincsState を更新
  const setSettingState = useCallback(
    values => dispatch(SettingsStateModules.actions.setSettings({ ...values })),
    [dispatch]
  )
  // loginState を更新
  const setLoginState = useCallback(
    values => dispatch(LoginStateModules.actions.setLogin({ ...values })),
    [dispatch]
  )

  const location = useLocation();
  let subtitle: JSX.Element = (<></>);
  let buttonDisabled: boolean = false;
  switch (location.pathname) {
    case '/results':
      subtitle = (<span className={styles['header-subtitle']}><span>|</span>検索結果</span>);
      break;
    case '/resultDetail':
      subtitle = (<span className={styles['header-subtitle']}><span>|</span>結果詳細</span>);
      break;
    case '/searchhistory':
      subtitle = (<span className={styles['header-subtitle']}><span>|</span>検索履歴</span>);
      break;
    case '/list':
      subtitle = (<span className={styles['header-subtitle']}><span>|</span>一時保存一覧</span>);
      buttonDisabled = true;
      break;
    case '/offline':
      buttonDisabled = true;
      break;
  }

  // isLoggedIn を取得
  const { isLoggedIn } = useSelector(selectLoginState)
  // console.log(isLoggedIn)

  const onClickAppButton = () => {
    if (isOnline()) {
      dispatch(push('/'))
    } else {
      alert('オフラインのため、検索画面へはいけません');
    }
  }

  const onClickLogoutButton = () => {
    if (confirm('ログアウトします。よろしいですか？')) { //eslint-disable-line
      // logout
      dispatch(setSettingState({ IdToken: '' }));
      dispatch(setLoginState({ isLoggedIn: false })); 
      dispatch(push('/login'))
    };
  }

  const onClickMoveButton = () => {
    dispatch(push('/list'))
  }

  let linkButton: JSX.Element = (<></>);
  if (isLoggedIn) {
    linkButton = (
      <div className={styles['header-btns']}>
        <div className={styles['header-button'] + ' ' + styles['header-button-logout']}><Button type={"secondary"} size={"ss"} onClick={() => onClickLogoutButton()}>ログアウト</Button><span>|</span></div>
        <div className={styles['header-button']}><Button type={"secondary"} size={"md"} onClick={() => onClickMoveButton()} disabled={buttonDisabled}>一時保存一覧</Button></div>
      </div>
    );
  }

  const offlineHeader: JSX.Element = (!isOnline()) ? (<div className={styles['header-offline']}>現在オフラインモード中です</div>) : (<></>);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <AppBar position="static" className={classes.header}>
          <Toolbar className={classes.bar + ' ' + styles['header-bar']}>
            <div className={styles['header-title']} onClick={() => onClickAppButton()}>過去事故検索アプリ{subtitle}</div>
            {linkButton}
          </Toolbar>
        </AppBar>
        { offlineHeader }
      </div>
    </div>
  )
}
export default Header