import { createSlice, PayloadAction } from '@reduxjs/toolkit'


// stateの型
export interface Message {
    type: ('info' | 'error' | 'warning')
    message: string
}

export interface MessageAreaState {
  open: boolean
  messages: Message[]
}

export const messageAreaState: MessageAreaState = {
  open: false,
  messages: []
}

const MessageAreaStateModules = createSlice({
  name: 'messageAreaState',
  initialState: messageAreaState,
  reducers: {
    setSearchResults: (state, action: PayloadAction<MessageAreaState>) => ({
        ...state,
        ...action.payload,
    }),
  },
})

export default MessageAreaStateModules