import React from 'react'
import styles from './styles.module.scss'

interface Props {
  onClick?: () => void
  icon: string
  text: string
  favorite: boolean
  className?: string
  onClickFavorite?: () => void
}

const TempListCard = React.memo(({ onClick, icon = '画像', text, favorite = false, onClickFavorite }: Props) => {

  return (
    <div className={styles.itemCard}>
      <div className={styles.itemInfo} onClick={onClick}>
        <div className={styles.typeIcon}>{icon}</div>
        <div className={styles.fileNameWrapper}><div className={styles.fileName}>{text}</div></div>
      </div>
     <button onClick={onClickFavorite} className={styles.favoriteButton}> <div className={styles.favorite + (favorite ? ' ' + styles.favorite_on : '')}></div></button>
    </div>
  )
});

export default TempListCard
