import Axios from '../../apis/axios';
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import Button from '../../components/atoms/Button'
import { selectSearchHistoryState, selectSettingsState } from '../../store/selector'
import React, { useCallback, useState } from 'react'
import SearchResultDetailStateModules, { data } from '../../store/searchResultDetailState'
//import { SearchResult } from '../../store/searchResultsState';
import SettingsStateModules from '../../store/settingsState';
import LoginStateModules from '../../store/loginState';
import { getErrorMessages } from '../../util/messageUtil';
import { isOnline } from '../../util/utils'
import { Messages } from '../../config/messages';
import { makeStyles } from '@material-ui/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SearchHistory } from '../../store/searchHistorysState';


/* ******************************
 * STYLES
 */
const useStyles = makeStyles({
  backdrop: {
    zIndex: 2,
    color: '#fff',
  }
});


const Searchhistory = () => {
  const classes = useStyles();

  // const xApiKey = process.env.REACT_APP_API_KEY
  const idToken = useSelector(selectSettingsState)

  const { searchHistorys } = useSelector(selectSearchHistoryState)
  const dispatch = useDispatch()

  // loader
  const [open, setOpen] = useState(false);

  // actionの呼出
  const setSearchResult = useCallback(
    values => dispatch(SearchResultDetailStateModules.actions.setSearchResult({ ...values })),
    [dispatch]
  )
  const setData = useCallback(
    values => dispatch(SearchResultDetailStateModules.actions.setData(values)),
    [dispatch]
  )
  // settincsState を更新
  const setSettingState = useCallback(
    values => dispatch(SettingsStateModules.actions.setSettings({ ...values })),
    [dispatch]
  )
  // loginState を更新
  const setLoginState = useCallback(
    values => dispatch(LoginStateModules.actions.setLogin({ ...values })),
    [dispatch]
  )

  const onClickDetailButton = (item: SearchHistory) => {
    getSearchResultDetail(item.jikobango, item.specificDisasterCode)
    setSearchResult(item)
  }

  // 検索APIを呼出
  const getSearchResultDetail = async (jikobango: string, specificDisasterCode: string) => {
    // オフラインの場合ログイン画面へ飛ばす
    if (!isOnline()) {
      // logout
      dispatch(setSettingState({ IdToken: '' }));
      dispatch(setLoginState({ isLoggedIn: false }));
      dispatch(push('/login'));
      return;
    }
    // if(xApiKey) {
    setOpen(true);

    await Axios
      ({
        method: 'get',
        url: '/get_accident_detail?accident_number=' + jikobango + '&specific_disaster_code=' + specificDisasterCode,
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken.IdToken
          // ,
          // 'x-api-key': xApiKey
        }
      })
      .then(res => {
        // 業務エラー
        let messages = [];
        if (res.data.error_type) {
          messages = getErrorMessages(res.data, 'search');
          if (messages) {
            alert(messages.join("\n"));
          }
        } else {
          // SyntaxError: Unexpected token o in JSON at position 1 エラーを回避するために先にstringifyしてからparseする
          const jsonData = JSON.parse(JSON.stringify(res.data.data))
          jsonData.data.map((item: { favorite: boolean; }) => {
            item.favorite = false
            return item
          })
          // 検索結果をstoreに保存
          const searchResultDetailData: data[] = []
          jsonData.data.map((data: any) => {
            const datum = {
              itemId: data.item_id,
              folderName: data.folder_name,
              fileName: data.file_name,
              url: data.url,
              favorite: data.favorite
            }
            return searchResultDetailData.push(datum)
          })
          setData(searchResultDetailData);
          dispatch(push("resultDetail"))
        }
        setOpen(false);
      })
      .catch(error => {
        setOpen(false);
        console.log(error, error.response);
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          if (error.response.status === 401) {
            alert(Messages.api.status401.message);
          } else if (error.response.status === 403) {
            alert(error.response.data.message);
          }
          // logout
          dispatch(setSettingState({ IdToken: '' }));
          dispatch(setLoginState({ isLoggedIn: false }));
          dispatch(push('/login'));
        } else if (error.response && (error.response.status === 502 || error.response.status === 504)) {
          alert(Messages.api.status502_detail.message);
        } else {
          dispatch(push('/error'));
        }
      });
    // }
  }

  return (
    <div className={styles.root}>
      <div className={styles.results}>
        <div className={styles['results-header']}>
          <div className={styles[`results-cell-kakutei`]}>検索日時</div>
          <div className={styles[`results-cell-syouken`]}>証券番号</div>
          <div className={styles[`results-cell-keiyaku`]}>契約者名（カナ）</div>
          <div className={styles[`results-cell-shozaichi`]}>所在地コード</div>
          <div className={styles[`results-cell-jiko`]}>事故番号</div>
          <div className={styles[`results-cell-ctrl`]}></div>
        </div>
        <div className={styles['results-list']}>
          {searchHistorys.length >= 0 && searchHistorys.map((item, i) =>
            <div className={styles['results-list-item']} key={i}>
              <div className={styles[`results-cell-kakutei`]}>{item.searchdate.toString().substring(5,7)+'/'+item.searchdate.toString().substring(8,10)}<br />{item.searchdate.toString().substring(11,16)}</div>
              <div className={styles[`results-cell-syouken`]}>{item.syoukenNum}</div>
              <div className={styles[`results-cell-keiyaku`]}>{item.keiyakusyaName}</div>
              <div className={styles[`results-cell-shozaichi`]}>{item.syozaichiCode}</div>
              <div className={styles[`results-cell-jiko`]}>{item.jikobango}</div>
              <div className={styles[`results-cell-ctrl`]}><Button type={"secondary"} onClick={() => onClickDetailButton(item)}>詳細</Button></div>
            </div>
          )}
        </div>
      </div>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}
export default Searchhistory