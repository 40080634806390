import CryptoJS from 'crypto-js';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';

// db
import { getDataByHash, getDataByHashWithFiles } from '../localdb/dbUtil/pastCases'

/**
 * SHA256ハッシュを生成する
 *
 * @param {string} value1
 * @param {string} value2
 * @return {string} 生成したハッシュ
 */
export const encrypt = (value1: string, value2: string): string => {
  const hash = sha256(value1 + value2);

  return Base64.stringify(hash);
}


/**
 * 一時保存データに受け取ったハッシュ値を持つデータがあるかどうかを捜査する
 *
 * @param {string} ハッシュ値
 * @return {Promise} 受け取ったハッシュ値をもつデータを返す
 */
export const searchTemporarySaveItem = (hash: string) => {
  return getDataByHash(hash);
}


/**
 * 一時保存データに受け取ったハッシュ値を持つデータがあるかどうかを捜査する
 *
 * @param {string} ハッシュ値
 * @return {Promise} 受け取ったハッシュ値をもつデータを返す
 */
export const getTemporarySaveItem = (hash: string) => {
  return getDataByHashWithFiles(hash);
}


/**
 * 受け取った文字列を暗号化する
 *
 * @param {string} dataStr - 受け取った文字列
 * @param {string} key - 受け取った文字列
 * @return {string} 暗号化したデータ
 */
export const encryptAES = (dataStr: string, key: string): string => {
  const ciphertext = CryptoJS.AES.encrypt(dataStr, key);
  return ciphertext.toString();
}

/**
 * 受け取った文字列を復号する
 *
 * @param {string} ciphertext - 受け取った文字列
 * @param {string} key - secret key
 * @return {string} 復号化したデータ
 */
export const decryptAES = (ciphertext: string, key: string): string => {
  const bytes  = CryptoJS.AES.decrypt(ciphertext, key);
  return bytes.toString(CryptoJS.enc.Utf8);
}

/**
 * 受け取ったObjectを暗号化する
 *
 * @param {string} dataStr - 受け取ったObject
 * @param {string} key - secret key
 * @return {string} 暗号化したデータ
 */
export const encryptObjectAES = (dataStr: Object, key: string): string => {
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(dataStr), key);
  return ciphertext.toString();
}

/**
 * 受け取った文字列をObjectに復号する
 *
 * @param {string} ciphertext - 受け取った文字列
 * @param {string} key - secret
 * @return {string} 復号化したデータ
 */
export const decryptObjectAES = (ciphertext: string, key: string): string => {
  const bytes  = CryptoJS.AES.decrypt(ciphertext, key);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}