import Axios from '../../apis/axios';
import { useState, useEffect, useCallback } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import Button from '../../components/atoms/Button'
import Modal from '../../components/atoms/Modal'
import DataIcon from '../../components/atoms/DataIcon'
import TextLabelItem from '../../components/molecules/TextLabelItem'
import ModalData from '../../components/Organisms/ModalData'
import { addPastCases, getDataByKey, updatePastCases } from '../../localdb/dbUtil/pastCases'
import { Prompt } from 'react-router'
import SearchResultDetailStateModules, { data } from '../../store/searchResultDetailState'
import SettingsStateModules from '../../store/settingsState';
import LoginStateModules from '../../store/loginState';
import { getErrorMessages } from '../../util/messageUtil';
import { isOnline } from '../../util/utils'
import { makeStyles } from '@material-ui/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// db
import { PastCases, PastCaseData } from "../../localdb/db"

// store
import { selectSearchResulDetailState, selectHashState, selectSettingsState } from '../../store/selector'

// util
import { groupBy } from '../../util/utils'
import { Messages } from '../../config/messages';


/* ******************************
 * STYLES
 */
const useStyles = makeStyles({
  backdrop: {
    zIndex: 2,
    color: '#fff',
  }
});

const createDate = new Date()


const ResultDetail = () => {
  const classes = useStyles();
  const dispatch = useDispatch()

  // loader
  const [open, setOpen] = useState(false);

  // actionの呼出
  const setData = useCallback(
    values => dispatch(SearchResultDetailStateModules.actions.setData(values)),
    [dispatch]
  )
  // settincsState を更新
  const setSettingState = useCallback(
    values => dispatch(SettingsStateModules.actions.setSettings({ ...values })),
    [dispatch]
  )
  // loginState を更新
  const setLoginState = useCallback(
    values => dispatch(LoginStateModules.actions.setLogin({ ...values })),
    [dispatch]
  )

  // storeから値を取得
  const idToken = useSelector(selectSettingsState)
  const { searchResult, data } = useSelector(selectSearchResulDetailState)
  let { hash } = useSelector(selectHashState)
  // console.log('store の hash:', hash)
  if (! hash) {
    // store にハッシュがなければsessionStorageを探す
    const sshash = sessionStorage.getItem('hash');
    hash = (sshash !== null) ? sshash : '';
    // console.log('sessionStorage の hash:', hash);
  }

  // 一時保存ボタンを押下したか保持
  const [isSaved, setIsSaved] = useState(false);

  // モーダルの表示切り替え
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // dbにデータがあるかどうかを保持
  const [existsIDB, setExistsIDB] = useState<boolean>(false);

  // SearchResultDetailオブジェクトをPastCasesオブジェクトに変換
  let pastCaseItem:PastCases = {
    id: searchResult.id,
    hash: hash,
    jikobangou: searchResult.jikobango,
    syoukenNum: searchResult.syoukenNum,
    keiyakusyaName: searchResult.keiyakusyaName,
    syozaichiCode: searchResult.syozaichiCode,
    specificDisasterCode: searchResult.specificDisasterCode,
    createDate: createDate,
    data: [...data]
  }

  // dataの読み込みが遅いのでuseEffectで検知
  useEffect(() => {
    getDataByKey(searchResult.id)
    .then((result: any) => {
      setExistsIDB((result === undefined) ? false : true);
    });

    // eslint-disable-next-line
    pastCaseItem = {
      id: searchResult.id,
      hash: hash,
      jikobangou: searchResult.jikobango,
      syoukenNum: searchResult.syoukenNum,
      keiyakusyaName: searchResult.keiyakusyaName,
      syozaichiCode: searchResult.syozaichiCode,
      specificDisasterCode: searchResult.specificDisasterCode,
      createDate: createDate,
      data: [...data]
    }
    setFiles(pastCaseItem)
  }, [data]);

  // 現在表示しているデータ
  const [files, setFiles] = useState<PastCases>(pastCaseItem)

  // refetch しても、即座にstore の filesのデータは切り替わらないので通常の変数として保持しておくために用意
  //  files自体はレンダリングに影響が出そうなので残しておく
  let newFiles = files;

  // データの再取得をしたかどうかを保持。再取得してもだめなら2回目の再取得はしない
  let reFetchFlag = false;

  // 画像表示ボタンをクリックした際、クリックされたボタンのfiles.data のインデックスを保持
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  const getFileType = (data: any): 'image' | 'pdf' => {
    let fileType;
    if (data.hasOwnProperty('url')) {
      fileType = data.url?.split('.').pop()?.split('?')[0].toLowerCase();
    } else if (data.hasOwnProperty('type')) {
      // fileType = data.type?.toLowerCase();
    }
    if (!fileType) return 'image';
    if (fileType.toLowerCase() === 'pdf') {
      return 'pdf';
    } else {
      return 'image';
    }
  }

  // storeに保持していたファイルの署名付きURLの有効期限が切れていたら、詳細データを取り直す
  const reFetchData = async () => {
    return Axios
    ({
      method: 'get',
      url: '/get_accident_detail?accident_number=' + searchResult.jikobango + '&specific_disaster_code=' + searchResult.specificDisasterCode,
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken.IdToken
        // ,
        // 'x-api-key': xApiKey
      }
    })
    .then(res => {
      // 業務エラー
      let messages = [];
      if (res.data.error_type) {
        messages = getErrorMessages(res.data, 'search');
        if (messages) {
          alert(messages.join("\n"));
        }
      } else {
        // SyntaxError: Unexpected token o in JSON at position 1 エラーを回避するために先にstringifyしてからparseする
        const jsonData = JSON.parse(JSON.stringify(res.data.data))
        jsonData.data.map((item: { favorite: boolean; }, i: number) => {
          // files のデータのfavoriteと同じ状態にする
          item.favorite = files.data[i].favorite;
          return item
        })

        // 検索結果をstoreに保存
        const searchResultDetailData: data[] = []
        jsonData.data.map((data: any) => {
          const datum = {
            itemId: data.item_id,
            folderName: data.folder_name,
            fileName: data.file_name,
            url: data.url,
            favorite: data.favorite
          }
          return searchResultDetailData.push(datum)
        })
        setData(searchResultDetailData);
        // useState の setter に値を突っ込んでも即時反映されないので、useStateとは別に普通の変数も用意し、両方更新する
        newFiles.data = searchResultDetailData;
        setFiles(newFiles);
        return true;
      }
    })
    .catch(error => {
      setOpen(false);
      console.log(error, error.response);
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        if (error.response.status === 401) {
          alert(Messages.api.status401.message);
        } else if (error.response.status === 403) {
          alert(error.response.data.message);
        }
        // logout
        dispatch(setSettingState({ IdToken: '' }));
        dispatch(setLoginState({ isLoggedIn: false }));
        dispatch(push('/login'));
      } else if (error.response && (error.response.status === 502 || error.response.status === 504)) {
        alert(Messages.api.status502.message);
      } else {
        dispatch(push('/error'));
      }
      return false;
    });
  }

  // DBに保存する
  const onClickAddPastCasesButton = async () => {
    setOpen(true);
    if (existsIDB) {
      // 既にDBにデータがあるなら更新
      updatePastCases(files)
      .then(() => {
        // 保存フラグをたてる
        setIsSaved(true);

        // hide  loading
        setOpen(false);

        // 保存まで完了したらフラグを戻す
        reFetchFlag = false;
      })
      .catch((error: any) => {
        console.log(error);
        // hide  loading
        setOpen(false);
      })
    } else {
      try {
        let types: Array<string | undefined>= [];

        const promises = newFiles.data.map((item: PastCaseData, i) => {
          if (item.hasOwnProperty('url') && item.url) {
            const req = new Request(item.url);
            types.push(item.url.split('?')[0].split('.').pop());

            return fetch(req)
              .then((res) => {
                if (res.status === 403) {
                  return undefined;
                } else {
                  return res.blob();
                }
              });
          } else {
            types.push(undefined);
            return undefined;
          }
        });
        // 全てのdata.url から画像をフェッチしてBlobとしてゲットする
        const blobs = await Promise.all(promises);
        // console.log(blobs);

        // blobをBase64エンコードする
        let count: number = 0;
        let base64s: string[] = [];

        // 署名付きURL画像期限切れでゲットできなければundefinedが入ってくる
        let success = true;
        for (let i = 0, l = blobs.length; i < l; i++) {
          if (blobs[i] === undefined) {
            success = false;
            break;
          }
        }
        if (!success) {
          // 取得したファイルのBlobデータがなければ、再度署名付きURLの取得を行う

          // オフラインの場合ログイン画面へ飛ばす
          if (!isOnline()) {
            // logout
            dispatch(setSettingState({ IdToken: '' }));
            dispatch(setLoginState({ isLoggedIn: false }));
            dispatch(push('/login'));
            return;
          }
          if (reFetchFlag) {
            // 再取得もダメならエラー
            dispatch(setSettingState({ IdToken: '' }));
            dispatch(setLoginState({ isLoggedIn: false }));
            dispatch(push('/error'));
            return;
          }
          reFetchFlag = true;
          reFetchData()
            .then((success) => {
              if (success) {
                onClickAddPastCasesButton();
              }
            });
        } else {
          // 取得したファイルのBlobデータに問題が無ければ一時保存へ進む
          const base64Encoded = (base64Str: (string | null), index: number) => {
            count++;
            if (count < blobs.length) {
              base64s[index] = base64Str || "";
            } else {
              base64s[index] = base64Str || "";

              // DB に保存
              let saveData = JSON.parse(JSON.stringify(newFiles));
              saveData.createDate = new Date();
              // ゲットしたBlobデータを突っ込む
              saveData.data.forEach((item: any, i: number) => {
                item.base64 = base64s[i];
                item.type = types[i];
              });
              // saveData.data = newFiles.data;

              // DBに追加
              addPastCases(saveData)
              .then(() => {
                // 保存フラグをたてる
                setIsSaved(true);

                // hide  loading
                setOpen(false);

                // 保存まで完了したらフラグを戻す
                reFetchFlag = false;

                // DBにデータが存在する
                setExistsIDB(true);
              })
              .catch((error: any) => {
                console.log(error);
                // hide  loading
                setOpen(false);
              })
            }
          }

          blobs.map((blob, i) => {
            let reader = new FileReader();
            /* @ts-ignore としましょう */
            reader.readAsDataURL(blob);
            return reader.onload = () => {
              /* @ts-ignore としましょう */
              base64Encoded(reader.result, i);
            }
          });

        }

      } catch (error) {
        console.log(`${error}`);
        // hide  loading
        setOpen(false);
      }
    }
  }

  // お気に入りをONしたかどうかをチェックする
  // ONしたものがあるかどうかをBooleanで返す
  const checkFavorite = () => {
    const favItems = files.data.filter(file => file.favorite);
    return favItems.length > 0;
  };

  // お気に入り登録する
  const onToggleFavorite = (index?: number) => {
    // files.data[currentIndex] の値を反転して更新
    let tmp = JSON.parse(JSON.stringify(files));
    tmp.data[index !== undefined ? index : currentIndex].favorite = !tmp.data[index !== undefined ? index : currentIndex].favorite;
    // setFiles({...files, data: tmp.data});
    setFiles(tmp)

    // 保存フラグをはずす（一時保存ボタンを活性化させる、一時保存せずに遷移する場合ポップアップを出す）
    setIsSaved(false);
  }

  const openModal = async (index: number) => {
    setCurrentIndex(index);

    setOpen(true);
    // ファイルを取得する（署名付きURLの有効確認）
    const req = new Request(files.data[index].url || '');
    const canGetFile = await fetch(req)
      .then((res) => {
        if (res.status === 403) {
          return false;
        } else {
          return true;
        }
      });
    // ファイルを取得できなかったら、get_accident_detail APIを再実行
    if (!canGetFile) {
      reFetchData()
        .then((success) => {
          if (success) {
            // モーダルを開く
            setOpen(false);
            setIsOpen(true);
          }
        });
    } else {
      // モーダルを開く
      setOpen(false);
      setIsOpen(true)
    }

  }

  const getFolderData = (item: PastCaseData[]) => {
    // 以下の順でソート
    // フォルダ名
    // 1,PDF・画像
    // 2,ファイル名
    item.sort((a, b) => {
      if(a.folderName! < b.folderName!) return  -1;
      if(a.folderName! > b.folderName!) return 1;
      if( getFileType(a) < getFileType(b) ) return 1;
      if( getFileType(a) > getFileType(b) )  return -1;
      if(a.fileName! < b.fileName!) return  -1;
      if(a.fileName! > b.fileName!) return 1;
      return 0
    });
    let folderData: JSX.Element[] = [];
    let group = groupBy(item, 'folderName'), j: number = 0;
    for (const property in group) {
      folderData.push(<div className={styles.folderName} key={'folder-' + j}>{'/' + property}</div>);
      folderData.push(<div className={styles.folderGroup} key={'folder-wrap-' + j}>
        {group[property].map((dItem: any, i:number) =>
          <DataIcon
            fileData={dItem}
            type={getFileType(dItem)}
            openModal={()=>openModal(dItem.defaultIndex)}
            onToggleFavorite={()=>onToggleFavorite(dItem.defaultIndex)}
            key={i}
          />
      )}
      </div>);
      j++;
    }
    // console.log(`folderData: ${folderData}`)
    return folderData;
  }

  return (
    <div className={styles.root}>
      <div className={styles.card}>
        <div className={styles['card-jikobango']}>
          <label>事故番号</label>{searchResult.jikobango}
        </div>
        <div className={styles['card-title']}>
          {searchResult.keiyakusyaName}
        </div>
        <div className={styles['card-info']}>
          <TextLabelItem label={'証券番号'} variant={'large'}>{searchResult.syoukenNum}</TextLabelItem>
          <TextLabelItem label={'所在地コード'} variant={'large'}>{searchResult.syozaichiCode}</TextLabelItem>
        </div>
        <div className={styles.itemCardList}>
          { getFolderData(files.data) }
        </div>
      </div>

      <div className={styles['card-button-field-save']}><Button size={"lg"} onClick={() => onClickAddPastCasesButton()} disabled={isSaved}>{isSaved ? "一時保存済み":"この内容を一時保存する"}</Button></div>

      <Modal onClose={()=>setIsOpen(false)} isActive={isOpen}>
        <div className={styles['xxxxx']}>
          <ModalData fileData={files.data[currentIndex]} onClick={()=>setIsOpen(false)} onToggleFavorite={() => onToggleFavorite()} />
        </div>
      </Modal>
        {/*
          お気に入りに変更を加えたが、一時保存していないときに画面遷移しようとするとアラート表示する。
          ログアウト処理をした場合は表示しない
        */}
        <Prompt
          message={(location, action) => {
            console.log(location.pathname)
            return location.pathname === '/login' ?
              true : (!isSaved && checkFavorite()) ?
                "一時保存していません。お気に入りが反映されませんがよろしいですか？" : true
          }}
        />
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}
export default ResultDetail