import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// stateの型
export interface SearchFiltersState {
    syoukenNum: string
    keiyakuName: string
    syozaichiCode: string
  }
  
  // 初期値
  export const SearchFiltersInitisalState: SearchFiltersState = {
    syoukenNum: '',
    keiyakuName: '',
    syozaichiCode: ''
  }
  
  const SearchFiltersStateModules = createSlice({
  name: 'SearchFiltersState',
  initialState: SearchFiltersInitisalState,
  reducers: {
    setSearchFilters: (state, action: PayloadAction<SearchFiltersState>) => ({
        ...state,
        ...action.payload,
    }),
  },
  })
  
  export default SearchFiltersStateModules
  