import { Messages } from "../config/messages";


/**
 * 業務エラーのエラータイプを元にエラーメッセージを作成する
 * @module getErrorMessages
 * @param {object} data - 項目名をキーとするオブジェクト
 * @param {string} category - メッセージのカテゴリ
 * @return {Array} - messageArea用のメッセージ情報の配列
 */
 export const getErrorMessages = (data: { error_type: any; message_id: any; error_message: any; error_item: object; }, category: string, isMessageOnly: boolean = false) => {
  let message: string[]  = [];
  switch (data.error_type) {
    case "E01":
      message = [`${data.message_id}：${data.error_message}` ];
      break;
    case "E02":
      message = getFieldTitle(data.error_item, category, isMessageOnly);
      break;
  }
  return message;
}

/**
 * バリデーションエラーの項目名から、フロントで持っている表示メッセージを使用したエラーメッセージを作成する
 * @module getFieldTitle
 * @param {object} keys - 項目名をキーとするオブジェクト
 * @param {string} category - メッセージのカテゴリ
 * @param {boolean} isMessageOnly - 受け取ったメッセージのみを使用するかどうか
 * @return {Array} - messageArea用のメッセージ情報の配列
 */
//  interface key {
//     [index: string]: any;
//   }
 export const getFieldTitle = (keys: object, category: string, isMessageOnly: boolean = false) => {
  return Object.keys(keys).map((key): string => {
    return (isMessageOnly) ? Messages[category][key].message : Messages[category][key].title + "は、" + Messages[category][key].message;
  });
}