import React from 'react';

// import SASS
import styles from './styles.module.scss'


/* ******************************
 * Types
 */
interface TextLabelProps {
  children: React.ReactNode
  variant?: "large"
}



/* ******************************
 * Component
 */
const TextLabel = (props: TextLabelProps): JSX.Element => {

  const variant = (props.variant) ? " " + styles['TextLabel--' + props.variant] : "";

  return (
    <label className={styles.TextLabel + variant}>
      {props.children}
    </label>
  );
};

export default TextLabel;