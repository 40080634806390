import { useState, useEffect, useCallback } from 'react'
import styles from './styles.module.scss'
import { useSelector } from 'react-redux'
import { Prompt } from 'react-router'
import Button from '../../components/atoms/Button'
import TempListCard from '../../components/atoms/Card/tempListCard'
import TextLabelItem from '../../components/molecules/TextLabelItem'
import Modal from '../../components/atoms/Modal'
import ModalData from '../../components/Organisms/ModalData'
import { makeStyles } from '@material-ui/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// import Mui
import { Paper } from '@material-ui/core';

// db
import { updatePastCases, deleteDataByKey } from '../../localdb/dbUtil/pastCases'
import { PastCaseData, PastCases } from '../../localdb/db'

// store
import { selectHashState } from '../../store/selector';

// util
import { isOnline, groupBy } from '../../util/utils'
import { getTemporarySaveItem } from '../../util/hash'


/* ******************************
 * STYLES
 */
const useStyles = makeStyles({
  backdrop: {
    zIndex: 2,
    color: '#fff',
  }
});

const TemporarySaveList = () => {
  const classes = useStyles();

  // storeから値を取得
  let { hash } = useSelector(selectHashState)
  // console.log('store の hash:', hash)
  if (! hash) {
    // store にハッシュがなければsessionStorageを探す
    const sshash = sessionStorage.getItem('hash');
    hash = (sshash !== null) ? sshash : '';
    // console.log('sessionStorage の hash:', hash);
  }

  // modal
  const [isOpen, setIsOpen] = useState<boolean>(false)

  // loader
  const [isLoading, setIsLoading] = useState(false);

  // 一時保存されている全データを保持
  const [allData, setAllData] = useState<PastCases[]>([]);
  // base64データは重いので分離して保持
  const [allBase64Data, setAllBase64Data] =useState<any[]>([]);
  // モーダルに渡すBase64データを含んだPastCaseData
  const [showFile, setShowFile] = useState<PastCaseData>();

  // 一時保存ボタンを押下したか保持
  const [isSaved, setIsSaved] = useState(true);

  // 読み込み時のお気に入りの状態を保持（保存時、変更があるかどうかの確認用）
  const [defaultFavoriteState, setDefaultFavoriteState] = useState<any[]>([]);

  // クリックされたファイルが所属するデータの、allData内でのインデックスを保持
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  // クリックされたファイルのインデックスを保持
  const [currentDataIndex, setCurrentDataIndex] = useState<number>(0);

  // 一時保存データを削除
  const deleteDBItem = (id: string, index: number, jikobangou: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(jikobangou + 'の一時保存データを削除します。\nよろしいですか？')) {
      // db から削除
      deleteDataByKey(id);

      // allDataから削除
      const tmpAllData = JSON.parse(JSON.stringify(allData));
      tmpAllData.splice(index, 1);
      setAllData(tmpAllData);

      // 保持していたお気に入りの初期値からも削除
      const tmpDefFav = JSON.parse(JSON.stringify(defaultFavoriteState));
      tmpDefFav.splice(index, 1);
      setDefaultFavoriteState(tmpDefFav);
    }
  }

  // お気に入り登録する
  const onToggleFavorite = useCallback((index = currentIndex, dataIndex = currentDataIndex) => {
    // currentFiles の favorite をトグルする
    let updateFiles = JSON.parse(JSON.stringify(allData));
    updateFiles[index].data[dataIndex].favorite = !updateFiles[index].data[dataIndex].favorite;
    setAllData(updateFiles);
    // update showFile
    let showFile = JSON.parse(JSON.stringify(updateFiles));
    showFile[index].data[dataIndex].base64 = allBase64Data[index][dataIndex];
    setShowFile(showFile[index].data[dataIndex]);

    // 保存フラグをはずす（一時保存ボタンを活性化させる、一時保存せずに遷移する場合ポップアップを出す）
    setIsSaved(false);
  }, [setAllData,setShowFile, setIsSaved, allData, allBase64Data, currentIndex, currentDataIndex]);

  const checkChangeFavorite = () => {
    // defaultFavoriteState で保持していた読み込み時のお気に入り状態と比較して、変更のある事案のindexを割り出す
    let changedIndexes: number[] = [];
    defaultFavoriteState.forEach((jian: any[], index: number) => {
      for (let i = 0, l = jian.length; i < l; i++) {
        if (allData[index].data[i].favorite !== jian[i]) {
          changedIndexes.push(index);
          break;
        }
      }
    });
    return changedIndexes;
  }

  const onClickAddPastCasesButton = async () => {
    // 変更のあった事案を保存
    setIsLoading(true);

    // defaultFavoriteState で保持していた読み込み時のお気に入り状態と比較して、変更のある事案のindexを割り出す
    let changedIndexes: number[] = checkChangeFavorite();

    // merge base64 data
    let saveData = JSON.parse(JSON.stringify(allData));
    saveData.forEach((jian: PastCases, idx: number) => {
      jian.data.forEach((file: PastCaseData, idx2: number) => {
        saveData[idx].data[idx2].base64 = allBase64Data[idx][idx2];
      });
    });
    const promises = changedIndexes.map((index: number) => {
      return updatePastCases(saveData[index]);
    });

    // 直列で処理する
    (async () => {
      for (let i = 0, l = promises.length; i < l; i++) {
        await promises[i];
        // console.log(i)
      }
      setIsSaved(true);
      setIsLoading(false);

      // 保持していたお気に入り状態も更新
      let temp = JSON.parse(JSON.stringify(defaultFavoriteState));
      changedIndexes.forEach((idx: number) => {
        temp[idx] = allData[idx].data.map((item) => item.favorite)
      });
      setDefaultFavoriteState(temp);
      // console.log(temp)
    })();

    // Promise.allSettled(promises)
    //   .then(() => {
    //     setIsSaved(true);
    //     setIsLoading(false);
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   })
  }

  // 画像表示モーダルを開く
  const openModal = useCallback((index: number, fileIndex: number) => {
    setCurrentIndex(index); // 事案のインデックス
    setCurrentDataIndex(fileIndex);
    let tmp = JSON.parse(JSON.stringify(allData[index].data[fileIndex]));
    tmp.base64 = allBase64Data[index][fileIndex];
    setShowFile(tmp);
    setIsOpen(true)
  }, [setCurrentIndex, setCurrentDataIndex, setIsOpen, allData, allBase64Data]);


  useEffect(() => {
    setIsLoading(true);

    // 自分のUUIDと同一のデータを取得
    getTemporarySaveItem(hash)
    .then((res: any) => {
      if (res.length > 0) {
        // まず事案をソート
        // 事故番号
        // 特定災害コード
        res.sort((a: PastCases, b: PastCases) => {
          if (a.jikobangou < b.jikobangou) return 1;
          if (a.jikobangou > b.jikobangou) return -1;
          if (a.specificDisasterCode < b.specificDisasterCode) return -1;
          if (a.specificDisasterCode > b.specificDisasterCode) return 1;
          return 0;
        });

        // ファイルを以下の順でソート
        // フォルダ名
        // 1,お気に入り
        // 2,PDF・画像
        // 3,ファイル名
        res.map((item: PastCases) => {
            return item.data.sort((a, b) => {
              if(a.folderName! < b.folderName!) return  -1;
              if(a.folderName! > b.folderName!) return 1;
              if(a.favorite < b.favorite) return 1 ;
              if(a.favorite > b.favorite) return -1 ;
              if( (a.type === 'pdf' || a.type === 'PDF' ? 'pdf' : '画像')  < (b.type === 'pdf' || b.type === 'PDF' ? 'pdf' : '画像' )) return -1;
              if( (a.type === 'pdf' || a.type === 'PDF' ? 'pdf' : '画像')  > (b.type === 'pdf' || b.type === 'PDF' ? 'pdf' : '画像' ) ) return 1;
              if(a.fileName! < b.fileName!) return - 1;
              if(a.fileName! > b.fileName!) return 1;
              return 0
            });
        })

        // separate base64 data
        let base64Data: any[] = [];
        res.forEach((item: PastCases) => {
          base64Data.push(item.data.map((file) => file.base64));
          item.data.forEach((file: PastCaseData) => {
            delete file.base64;
          });
        });
        setAllBase64Data(base64Data);

        setAllData(res);
        setIsLoading(false);

        // 初期のお気に入りの状態を保存
        let defaultFavoriteState: any[] = [];
        res.forEach((jian: PastCases) => {
          defaultFavoriteState.push(jian.data.map((item) => item.favorite));
        });
        setDefaultFavoriteState(defaultFavoriteState);
      } else {
        setAllData([]);
        setIsLoading(false);
      }
    });

    return () => {
      // 一時保存画面では、既に一時保能されているデータなので、変更は常にDBをアップデートする。
      // そのため、unmout時に一時保存していないアラートは不要
    }
    // eslint-disable-next-line
  }, []);

  const getFolderData = (item: PastCaseData[], parentIndex: number) => {
    let folderData: JSX.Element[] = [];
    let group = groupBy(item, 'folderName'), j: number = 0;
    for (const property in group) {
      folderData.push(<div className={styles.folderName} key={'folder-' + j}>{'/' + property}</div>);
      folderData.push(<div className={styles.folderGroup} key={'folder-wrap-' + j}>
        {group[property].map((dItem: any, i: number) =>
          <TempListCard
            key={`cardItem-${parentIndex}-${dItem.defaultIndex}`}
            icon={dItem.type === 'pdf' ||  dItem.type === 'PDF' ? 'PDF' : '画像'}
            text={dItem.fileName}
            favorite={dItem.favorite}
            onClick={() => openModal(parentIndex, dItem.defaultIndex)}
            onClickFavorite={() => onToggleFavorite(parentIndex, dItem.defaultIndex)}
          />
        )}
      </div>);
      j++;
    }
    return folderData;
  }

  if (allData.length > 0) {

    return (
      <div className={styles.root + (isOnline() ? '' : ' ' + styles.offline)}>
        <div className={styles.list}>
          {allData.map((item, i) =>
            <div className={styles.card} key={'card' + item.id}>
              <Button className={styles.deleteButton} type={'quaternary'} onClick={() => deleteDBItem(item.id, i, item.jikobangou)}>一時保存の削除</Button>
              <div className={styles['card-jikobango']}><label>事故番号</label>{item.jikobangou}</div>
              <div className={styles.cardInfo}>
                <div className={styles.cardInfoTitle}>{item.keiyakusyaName}</div>
                <TextLabelItem label={['証券', <br key={'item-label-' + item.id + '_1'} />, '番号']}>{item.syoukenNum}</TextLabelItem>
                <TextLabelItem label={['所在地', <br key={'item-label-' + item.id + '_2'} />, 'コード']}>{item.syozaichiCode}</TextLabelItem>
              </div>
              <div className={styles.itemCardList}>
              { getFolderData(item.data, i) }
              </div>
            </div>
          )}

          <div className={styles['card-button-field-save']}><Button size={"lg"} onClick={() => onClickAddPastCasesButton()} disabled={isSaved}>{isSaved ? "一時保存済み":"この内容で更新する"}</Button></div>

          <Modal onClose={()=>setIsOpen(false)} isActive={isOpen}>
            <div className={styles['xxxxx']}>
              <ModalData fileData={showFile} onClick={()=>setIsOpen(false)} onToggleFavorite={() => onToggleFavorite()} />
            </div>
          </Modal>
        </div>
        {/*
          お気に入りに変更を加えたが、一時保存データを更新していないときに画面遷移しようとするとアラート表示する。
          ログアウト処理をした場合は表示しない
        */}
        <Prompt
          message={(location, action) => {
            console.log(location.pathname)
            return location.pathname === '/login' ?
              true : (!isSaved && checkChangeFavorite().length > 0) ?
                "変更を保存していません。お気に入りが反映されませんがよろしいですか？" : true
          }}
        />
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    )

  } else {

    return (
      <div className={styles.Error}>
        <Paper className={styles['Error__base']}>
          <p className={styles['Error__title']}>
            { isLoading ? 'データを読み込んでいます' : '一時保存したデータがありません'}
          </p>
        </Paper>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    )

  }
}
export default TemporarySaveList