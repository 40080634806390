import { useEffect, useCallback } from 'react';
import styles from './styles.module.scss'
import { useDispatch } from 'react-redux';

// store
import SettingsStateModules from '../../store/settingsState';
import LoginStateModules from '../../store/loginState';

// import Mui
import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { push } from 'connected-react-router';



/* ******************************
 * Component
 */
const Error = (): JSX.Element => {
  const dispatch = useDispatch();

  // settincsState を更新
  const setSettingState = useCallback(
    values => dispatch(SettingsStateModules.actions.setSettings({ ...values })),
    [dispatch]
  )
  // loginState を更新
  const setLoginState = useCallback(
    values => dispatch(LoginStateModules.actions.setLogin({ ...values })),
    [dispatch]
  )

  useEffect(() => {
    // メッセージエリアを閉じる
    // dispatch(closeMessage());

    // logout
    dispatch(setSettingState({ IdToken: '' }));
    dispatch(setLoginState({ isLoggedIn: false })); 

    // 保持しているデータはクリア
    // dispatch(clearData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goLogin = () => {
    dispatch(push('/login'));
  }

  return (
    <div className={styles.Error}>
      <Paper className={styles['Error__base']}>
        <p className={styles['Error__title']}>
          現在使用できません
        </p>
        <p className={styles['Error__main-text']}>
          大変お手数ですが、管理者までご連絡ください。
        </p>

        <Paper className={styles['Error__sub-base']}>
          <Button className={styles['Error__link-button']} variant="outlined" onClick={goLogin}>ログイン画面へ</Button>
        </Paper>
      </Paper>
    </div>
  );
};

export default Error;