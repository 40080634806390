import Dexie, { Table } from 'dexie';

export interface PastCaseData {
  itemId: string
  pastCaseId?: string
  folderName: string
  fileName: string
  // blob?: Blob
  base64?: string
  type?: string
  url?: string
  favorite: boolean
}

export interface PastCases {
  id: string
  hash: string
  jikobangou: string
  syoukenNum: string
  keiyakusyaName: string
  syozaichiCode: string
  specificDisasterCode: string
  createDate: Date
  data: PastCaseData[]
}

export class MySubClassedDexie extends Dexie {
  pastCases!: Table<PastCases>;
  files!: Table<PastCaseData>;

  constructor() {
    super('localdatabase');
    this.version(1).stores({
        pastCases: 'id, hash, jikobangou, syoukenNum, keiyakusyaName, syozaichiCode, specificDisasterCode, createDate, data' // Primary key and indexed props
    });

    this.version(2).stores({
      pastCases: 'id, hash, createDate',
      files: '[pastCaseId+folderName+fileName]'
    }).upgrade( async tx => {
      // version 1 では、pastCases.data にファイル情報を保持していたが、version 2 では、filesで管理するように変更

      // version1 の pastCase.data のファイル情報を version2 の files にコピー
      let files = tx.table('files');
      await tx.table('pastCases').toCollection().each(pastCase => {
        for (let i = 0, l = pastCase.data.length; i < l; i++) {
          files.add({
            pastCaseId: pastCase.id,
            ...pastCase.data[i]
          });
        }
      });

      // version1 の pastCase.data のファイル情報を空に
      return tx.table('pastCases').toCollection().modify(pastCase => {
        pastCase.data = [];
      })
    })
  }
}

export const db = new MySubClassedDexie();