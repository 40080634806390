import { db, PastCases } from "../db"

// debug info: send to API
import Axios from '../../apis/axios';
import { AxiosRequestConfig } from 'axios';
import { getUUID } from '../../util/utils';

/**
 * IndexedDBのデータを追加
 *
 * @param {PastCases} data
 */
export const addPastCases = async (data: PastCases) => {
  // Add the new pastCases!
  await db.transaction('rw', db.pastCases, db.files, (tx) => {
    // console.log('Transaction Scope', tx);
    db.pastCases.add({
      id: data.id,
      hash: data.hash,
      jikobangou: data.jikobangou,
      syoukenNum: data.syoukenNum,
      keiyakusyaName: data.keiyakusyaName,
      syozaichiCode: data.syozaichiCode,
      specificDisasterCode: data.specificDisasterCode,
      createDate: data.createDate,
      data: []
    });

    // ファイルを一括で登録
    // const files = data.data.map((item: any) => {
    //   return {
    //     pastCaseId: data.id,
    //     ...item
    //   }
    // });
    // db.files.bulkAdd(files);

    // ファイルを１ファイルづつ登録
    const promises = data.data.map((item: any) => {
      return () => {
        // 以前
        // (1)pastCasesテーブルにデータなし、filesにデータありの状態でエラーが出た
        // (2)証券番号「M98765432109」で, item_id が異なるだけで同じファイルを参照しているデータがあり、filesテーブルのKeyにitem_idを使用していないためエラーになった
        // (1)(2)ともに本番では起こり得ないということで変更しないという判断。
        // ※いずれの問題もadd(insert)をput(upsert)に変更することでエラーは回避できた。
        // db.files.put({
        db.files.add({
          pastCaseId: data.id,
          ...item
        });
      }
    });
    // 直列で処理する
    (async () => {
      for (let i = 0, l = promises.length; i < l; i++) {
        // console.time('files')
        await promises[i]();
        // console.timeEnd('files')
      }
    })();
  })
  .then(async () => {
    // トランザクション正常終了後に処理をしたい場合はここに書く
    console.log('Transaction committed!');
  })
  .catch((error) => {
    console.log('Transaction Failed...', error)
    sendError(error, 'addPastCases');

    // DBがクローズしていたら、以後の処理を通さずにエラーを戻す
    if (! checkDBOpen()) throw new Error();

    // 容量いっぱいでもDBがCLOSEしていない場合（PCなども）のために残す
    if(error['name'] === 'QuotaExceededError' || error.inner['name'] === 'QuotaExceededError') {
      alert('容量をオーバーしています。一時保存画面から不要な過去事案を削除してください')
    } else {
      alert('一時保存に失敗しました。もう一度お試しください')
    }
    throw new Error();
  })
}


/**
 * IndexedDBのデータを上書き
 * PastCases のデータを渡すと、主キー（id）を使って更新する
 *
 * @param {PastCases} data
 * @return {boolean} - 成功かどうか
 */
 export const updatePastCases = async (data: PastCases): Promise<any> => {
  const key: string = data.id;

  await db.transaction('rw', db.pastCases, db.files, (tx) => {
    // console.log('Transaction Scope', tx);
    db.pastCases.update(key, {
      id: data.id,
      hash: data.hash,
      jikobangou: data.jikobangou,
      syoukenNum: data.syoukenNum,
      keiyakusyaName: data.keiyakusyaName,
      syozaichiCode: data.syozaichiCode,
      specificDisasterCode: data.specificDisasterCode,
      createDate: data.createDate,
      data: []
    })

    // files を更新
    data.data.forEach((item) => {
      // ユニークなIDが無いので、folderNameとfileNameを連結した文字列でファイルを抽出して更新する。
      // 変更があるのはお気に入り情報だけなので、お気に入り情報だけを更新する
      db.files.where({'pastCaseId': data.id ,'folderName': item.folderName, 'fileName': item.fileName}).modify({'favorite': item.favorite});
    });
  })
  .then(() => {
    console.log('Transaction committed!');
    return true;
    // if (updated) {
    //   return updated;
    // } else {
    //   throw new Error();
    // }
  }).catch((error) => {
    console.log('Transaction Failed...', error)
    sendError(error, 'updatePastCases');

    // DBがクローズしていたら、以後の処理を通さずにエラーを戻す
    if (! checkDBOpen()) throw new Error();

    if(error['name'] === 'QuotaExceededError' || error.inner['name'] === 'QuotaExceededError') {
      alert('容量をオーバーしています。一時保存画面から不要な過去事案を削除してください')
    } else {
      alert('一時保存に失敗しました。もう一度お試しください')
    }
    throw new Error();
  })
}


/**
 * 全事案取得
 *
 * @return {PastCases[]} 該当データ
 */
// export const getAllPastCases = () => {
//   const allPastCases = db.pastCases.toArray()
//   return allPastCases
// }


/**
 * IndexedDBから指定された主キーの値を返却
 *
 * @param {any} key
 * @return {any} 該当データ。filesは含まない。存在しない場合はundefined
 */
export const getDataByKey = (key: any): any => {
  return db.pastCases.get(key)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log('Data Get Failed...', error);
      sendError(error, 'updatePastCases');
      // DatabaseClosedErrorが起こると復帰できないためチェック
      if (checkDBOpen()) throw new Error();
    })
}


/**
 * IndexedDBから指定されたhashの値を持つデータ返却
 *
 * @param {any} hash
 * @return {any} 該当データ。filesは含まない。存在しない場合はundefined
 */
export const getDataByHash = (hash: any) => {
  return db.transaction('r', db.pastCases, (tx) => {
    return db.pastCases.where('hash').equals(hash).toArray();
  })
  .then((result) => {
    console.log('Transaction Commited!');
    return result;
  })
  .catch((error) => {
    console.log('Transaction Faild...', error);
    sendError(error, 'getDataByHash');

    // DatabaseClosedErrorが起こると復帰できないためチェック
    if (checkDBOpen()) return [];
  })
}


/**
 * IndexedDBから指定されたhashの値を持つデータ返却
 *
 * @param {any} hash
 * @return {pastCases[]} 該当データ。filesを含む。存在しない場合はundefined
 */
export const getDataByHashWithFiles = async (hash: any) => {
  // DatabaseClosedErrorが起こると復帰できないためチェック
  if (! checkDBOpen()) return [];

  const pastCases = await db.pastCases.where('hash').equals(hash).toArray();
  if (pastCases.length > 0) {
    await Promise.all(pastCases.map(async (pastCase: PastCases) => {
      return pastCase.data = await db.files.where('pastCaseId').equals(pastCase.id).toArray();
    }));
  }
  return pastCases;
}


/**
 * IndexedDBから指定された主キーの値を削除
 *
 * @param {any} key
 */
export const deleteDataByKey = (key: any) => {
  return db.transaction('rw', db.pastCases, db.files, (tx) => {
    db.pastCases.delete(key);
    db.files.where('pastCaseId').equals(key).delete().then((deleteCount) => {
      console.log('Deleted ' + deleteCount + ' files.');
    });
  })
  .then(() => {
    console.log('Transaction Commited!');
  })
  .catch((error) => {
    console.log('Transaction Faild...', error);
    sendError(error, 'deleteDataByKey');

    // DatabaseClosedErrorが起こると復帰できないためチェック
    if (checkDBOpen()) throw new Error();
  })
}


/**
 * IndexedDBから一番最後に登録されたデータを削除
 * 容量いっぱい時のDB close 問題回避用処理
 */
export const deleteRecentData = () => {
  return db.transaction('rw', db.pastCases, db.files, (tx) => {
    db.pastCases.orderBy('createDate').reverse().limit(1).toArray()
      .then(result => {
        if (result.length > 0) {
          const syoukenNum = result[0].syoukenNum;
          const jikobangou = result[0].jikobangou;
          deleteDataByKey(result[0].id)
            .then(() => {
              alert('一時保存容量オーバーで不安定な状態となった為、直近に一時保存した事案を削除しました。\n一時保存一覧で不要な事案を削除したうえで、次の事案の一時保存を再度行ってください。\n\n証券番号：' + syoukenNum + '\n事故番号：' + jikobangou + '\n\n※検索もしくは検索履歴から詳細を取得して一時保存してください。');
            });
        }
      })
  })
  .catch((error) => {
    throw new Error();
  })
}


/**
 * IndexedDBからcreateDateが3日(72時間)以上前のデータを削除
 */
 export const deleteExpiredData = async () => {
  const now = new Date().getTime();
  const expired = (60 * 60 * 24 * 3 * 1000); // 3日
  const items = await db.pastCases.filter(item => now - new Date(item.createDate).getTime() > expired).toArray();
  items.forEach((item) => {
    const id = item.id;
    db.pastCases.delete(id);
    db.files.where('pastCaseId').equals(id).delete();
  })
}


/**
 * DBが閉じているかチェック
 */
export const checkDBOpen = () => {
  // DB容量がいっぱいになってしまうとDBがcloseしてしまうケースがあり、これが起こるとDBクリアしないと復帰できなくなる（一時保能周りが表示できない、保存できないなど）
  // 初回起動時はまだDBにアクセスできることが分かったため、フラグを立てて直近のファイルを削除するようにする
  if (!db.isOpen()) {
    // localStorageにフラグを立てる
    localStorage.setItem('DB_QUOTA_EXCEEDED', 'true');
    // alert の OKが押されると undefined が返ってくるのでリロード
    // @ts-ignore
    if (! alert('一時保存容量オーバーの為、ログイン画面に戻り、直近に一時保存した事案を削除します。')) {
      window.location.reload();
      return false;
    }
  } else {
    return true;
  }
}


// Debug用
/**
 * /auth/login にエラー内容を送信
 */
export const sendError = async (error: any, timing: string) => {
  // 現状のストレージ情報を取得
  // https://web.dev/i18n/ja/storage-for-the-web/#check
  const getStorageInfo = async () => {
    if (navigator.storage && navigator.storage.estimate) {
      try {
        const quota = await navigator.storage.estimate();
        // @ts-ignore
        const percentageUsed = (quota.usage / quota.quota) * 100;
        // @ts-ignore
        const remaining = quota.quota - quota.usage;
        const remainingMB = Math.floor(remaining / 10204 / 1024 * 100) / 100;
        return {
          used: `${percentageUsed}%`,
          remaining: `${remaining}B (${remainingMB}MB)`
        }
      } catch (error) {
        console.log(error);
        return {};
      }
    } else {
      return {};
    }
  }
  // バージョンとストレージの状況を取得
  const storageInfo = await getStorageInfo();
  const appInfo = {
    ver_info: {
      app_ver: process.env.REACT_APP_VERSION,
      db_ver: db.verno
    },
    storage_info: storageInfo
  }

  const config: AxiosRequestConfig = {
    method: 'post',
    url: '/auth/login',
    data: {
      params: {
        timing: timing,
        error: error.toString(),
        uuid: getUUID(),
        app_info: JSON.stringify(appInfo)
      },
    },
    headers: {
      'accept': '*/*',
      'Content-Type': 'application/json'
    }
  }

  Axios(config)
  .then(res => {
    console.log("error sent");
  })
}