import { useCallback, useState, useEffect } from 'react'
import Axios from '../../apis/axios';
import { AxiosRequestConfig } from 'axios';
import styles from './styles.module.scss'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/atoms/Button'
import InputField from '../../components/atoms/Input/InputField'

// store
import SettingsStateModules from '../../store/settingsState';
import LoginStateModules from '../../store/loginState';
import HashStateModules from '../../store/hashState';
import { selectLoginState } from '../../store/selector';

// db
import { deleteExpiredData } from '../../localdb/dbUtil/pastCases';

// MUI
import { makeStyles } from '@material-ui/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// util
import { isOnline, getUUID } from '../../util/utils'
import { encrypt, searchTemporarySaveItem } from '../../util/hash'

// assets
import SompoJapanLogo from '../../images/sompojapan.svg'
import { Messages } from '../../config/messages';
import { getErrorMessages } from '../../util/messageUtil';
import { db } from '../../localdb/db';

/* ******************************
 * Types
 */
interface ValuesType {
  id: string
  password: string
}


/* ******************************
 * STYLES
 */
const useStyles = makeStyles({
  backdrop: {
    zIndex: 2,
    color: '#fff',
  }
});


/**
 * 仮のデフォルト値
 */
const defaultValues: ValuesType = {
  id: (process.env.REACT_APP_LOGIN_ID) ? process.env.REACT_APP_LOGIN_ID : '',
  password: (process.env.REACT_APP_LOGIN_PW) ? process.env.REACT_APP_LOGIN_PW : ''
}



const Login = () => {

  // const xApiKey = (process.env.REACT_APP_API_KEY) ? process.env.REACT_APP_API_KEY : '';

  const dispatch = useDispatch()
  const classes = useStyles();

  // settincsState を更新
  const setSettingState = useCallback(
    values => dispatch(SettingsStateModules.actions.setSettings({ ...values })),
    [dispatch]
  )

  // loginState を更新
  const setLoginState = useCallback(
    values => dispatch(LoginStateModules.actions.setLogin({ ...values })),
    [dispatch]
  )

  // hashState を更新
  const setHashState = useCallback(
    values => dispatch(HashStateModules.actions.setHash({ ...values })),
    [dispatch]
  )

  // ログイン中かどうかを取得
  const { isLoggedIn } = useSelector(selectLoginState)
  // console.log('isLoggedIn : ', isLoggedIn)

  // loader
  const [open, setOpen] = useState(false);

  const [inputIDValue, setInputIDValue] = useState<string>(defaultValues.id)
  const [inputPWValue, setInputPWValue] = useState<string>(defaultValues.password)

  // 現状のストレージ情報を取得
  // https://web.dev/i18n/ja/storage-for-the-web/#check
  const getStorageInfo = async () => {
    if (navigator.storage && navigator.storage.estimate) {
      try {
        const quota = await navigator.storage.estimate();
        // quota.usage -> Number of bytes used.
        // quota.quota -> Maximum number of bytes available.
        // @ts-ignore
        const percentageUsed = (quota.usage / quota.quota) * 100;
        console.log(`You've used ${percentageUsed}% of the available storage.`);
        // @ts-ignore
        const remaining = quota.quota - quota.usage;
        const remainingMB = Math.floor(remaining / 10204 / 1024 * 100) / 100;
        console.log(`You can write up to ${remaining} more bytes(${remainingMB}MB).`);
        return {
          used: `${percentageUsed}%`,
          remaining: `${remaining}B (${remainingMB}MB)`
        }
      } catch (error) {
        console.log(error);
        return {};
      }
    } else {
      return {};
    }
  }



  const login = async (uuid: string) => {

    // バージョンとストレージの状況を取得
    const storageInfo = await getStorageInfo();
    const appInfo = {
      ver_info: {
        app_ver: process.env.REACT_APP_VERSION,
        db_ver: db.verno
      },
      storage_info: storageInfo
    }
    // console.log(appInfo)

    const config: AxiosRequestConfig = {
      method: 'post',
      url: '/auth/login',
      data: {
        params: {
          user_id: inputIDValue.toLowerCase(),
          password: inputPWValue,
          uuid: uuid,
          app_info: JSON.stringify(appInfo)
        },
      },
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json'
        // ,x-api-key': xApiKey
      }
    }

    return Axios(config)
    .then(res => {
      // 業務エラー
      let messages = [];
      if (res.data.error_type) {
        messages = getErrorMessages(res.data, 'search');
        if (messages) {
          alert(messages.join("\n"));
        }
      }else {
        // 検索結果をstoreに保存
        return res.data.IdToken;
      }
    })
    .catch(error => {
      console.log(error, error.response);
      if (error.response && error.response.status === 400) {
        alert(error.response.data.error_message);
        dispatch(push('/login'));
      } else if (error.response && (error.response.status === 502 || error.response.status === 504)) {
        alert(Messages.api.status502.message);
      } else {
        dispatch(push('/error'));
      }
      return false;
    });
  }

  const onClickLoginButton = async () => {
    if (isOnline()) {
      // inputのonChangeでバリデーションを行っているが、
      // Submitしたときにバリデーションエラーが発生していても送信されてしまう。
      // そのためSubmit時にもバリデーションを行い、バリデーションエラーの時は送信処理を行わない。
      var idRegex = new RegExp(/^[0-9A-Za-z]+$/);
      var pwRegex = new RegExp(/(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!-\\/:-@[-`{-~])[!-~]{8,128}/);

      // オンラインならAPI叩いてログイン処理。通常のオンラインフローへ
      if (idRegex.test(inputIDValue) && pwRegex.test(inputPWValue)) {
        setOpen(true);

        // UUID取得
        const uuid = getUUID();

        //ログイン処理
        const idToken = await login(uuid);

        setOpen(false);
        if (idToken) {
          // hash 生成（ユーザIDは小文字として扱う）
          const newHash: string = encrypt(inputIDValue.toLowerCase(), inputPWValue);

          // sessionStorageに保存(リロード対策)
          // sessionStorage.setItem('IdToken', idToken);
          sessionStorage.setItem('hash', newHash);

          // settingsに保存
          dispatch(setSettingState({ IdToken: idToken }));

          // usersに保存
          dispatch(setLoginState({ isLoggedIn: true }));

          // hashに保存
          dispatch(setHashState({ hash: newHash, id: inputIDValue }));

          dispatch(push('/'))
        } else {
          console.log('ログインできませんでした');
        }
      }

    } else {
      // オフラインならIDとパスワードをハッシュ化し、そのハッシュ値と同じ一時保存ファイルがあるかをチェック
      // 一時保存ファイルがあれば認証OKとしてオフラインフローへ

      // （ユーザIDは小文字として扱う）
      const hash = encrypt(inputIDValue.toLowerCase(), inputPWValue);
      searchTemporarySaveItem(hash)
      .then((res: any) => {
        if (res.length > 0) {
          // 成功したら「isLoggedIn」のステートをtrue にして一時保存一覧へ
          // usersに登録
          dispatch(setLoginState({ isLoggedIn: true }));
          // hashに保存
          dispatch(setHashState({ hash: hash }));
          sessionStorage.setItem('hash', hash);
          // 一時保存画面へ遷移
          dispatch(push('/list'))
        } else {
          // 失敗した場合はオフラインエラー表示画面へ？
          dispatch(push('/offline'))
        }
      });
      // if (hasTempSaveItems.length > 0) {
      //   // 成功したら「isLoggedIn」のステートをtrue にして一時保存一覧へ
      //   // usersに登録
      //   dispatch(setLoginState({ isLoggedIn: true }));
      //   // 一時保存画面へ遷移
      //   dispatch(push('/list'))
      // } else {
      //   // 失敗した場合はオフラインエラー表示画面へ？
      //   dispatch(push('/offline'))
      // }
    }
  }

  useEffect(() => {
    // 3日以上経過したIndexedDBのデータは削除する
    deleteExpiredData();

    // ログイン中ならホームへ戻す
    if (isLoggedIn) {
      if (isOnline()) {
        dispatch(push('/'));
      } else {
        // オフラインならそのままログイン画面を表示
        // dispatch(push('/list'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className={styles.Login}>
      <div className={styles.Login__inner}>
        <div className={styles.Login__logo}>
          <img src={SompoJapanLogo} alt="Logo" />
        </div>
        <div className={styles.Login__form}>
          <div className={styles.Login__stage}>
            <div>
              <div className={styles.Login__input}>
                <InputField name="アカウントID" value={inputIDValue} setValue={setInputIDValue} inputProps={{required: 'required', inputMode: 'url', pattern: '^[0-9A-Za-z]+$', title: '半角英数字で入力してください', autoComplete: 'off'}} />
              </div>
              <div className={styles.Login__input}>
                <InputField name="パスワード" value={inputPWValue} setValue={setInputPWValue} inputProps={{required: 'required', pattern: '(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!-\\/:-@[-`{-~])[!-~]{8,128}', maxLength: 128, title: '8桁以上の半角英数大文字小文字記号混在で入力してください', type:"password", autoComplete: 'off'}} />
              </div>
            </div>
          </div>
          <div className={styles.Login__submit}>
            <Button size={"lg"} onClick={() => onClickLoginButton()}>ログイン</Button>
          </div>
        </div>
      </div>
      <div className={styles.version}>ver.{process.env.REACT_APP_VERSION}</div>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )

}
export default Login