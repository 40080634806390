// メッセージリスト
export const Messages:{[key: string]: any} = {

    // API関連
    api: {
      status401: {
        message: "ログインの時間切れになりました。再度ログインしてください"
      },
      status502: {
        message: "処理が混み合っています。もう一度実行ください、しばらく経ってから実行しても同じ状況が続く場合は管理者に確認ください。"
      },
      status502_detail: {
        message: "当該事故の詳細情報データ容量が大きく通信状況により取得できなかった可能性があります。もう一度実行ください、同じ状況が続く場合は管理者に確認ください。"
      }
    },
  
    // ログイン
    login: {
      email: {
        title: "ID",
        message: "半角英数記号で入力してください"
      },
      password: {
        title: "パスワード",
        message: "8桁以上の半角英数大文字小文字記号混在で入力してください"
      }
    },
  
    search: {
        syoken_num: {
            title: "証券番号",
            message: "英数字で入力してください"
        },
        keiyakusya_name: {
            title: "契約者名",
            message: "カタカナで入力してください"
        },
        syozaichi_cd: {
          title: "所在地コード",
          message: "英数字で入力してください"
      } 
    },
    // 責任割合算定システム連携
    sekinin_wariai: {
      accident_number: {
            title: "",
            message: "対象事案が存在しませんでした"
        },
        movie_id: {
            title: "",
            user_id: "対象事案が存在しませんでした"
        }
    },
  }