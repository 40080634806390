import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

// store
import { selectLoginState } from './store/selector';

/* @ts-ignore */
const Auth = ({children}) => {

  const dispatch = useDispatch();

  // isLoggedIn を取得
  const { isLoggedIn } = useSelector(selectLoginState)
  // console.log('isLoggedIn : ', isLoggedIn)

  // componentDidMount
  useEffect(() => {
    if (!isLoggedIn) {
      // ストアの値がログイン状態でない場合、ログイン状態チェックAPIでログイン状態を更新する
        dispatch(push('/login'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoggedIn) {
    // ログインしていなければ空のJSXを返す
    return <></>;
  } else {
    // ログインしていれば、RouterコンポーネントのAuth以下の本来のデータを返す（＝Auth以下の本来のルーティングが走る。）
    return children;
  }

};

export default Auth;