import { v1 as uuidv1 } from 'uuid'

/**
 * オンラインかどうかをチェックする
 * 
 * @return {boolean} オンラインであれば「true」を返す
 */
export const isOnline = (): Boolean => {
    return window.navigator.onLine;
}

/**
 * 文字列中の全角文字を半角へ変換
 * @module toHalfWidth
 * @param {string} value - 対象の文字列
 * @return {string} - 変換後の文字列
 */
export const toHalfWidth = (value: string): string => {
  if (!value) return value;

  // 全角英数を半角へ
  let str = String(value).replace(/[！-～]/g, function(all) {
    return String.fromCharCode(all.charCodeAt(0) - 0xFEE0);
  });
  // 文字コードシフトで対応できない文字の変換
  str = str.replace(/”/g, "\"")
            .replace(/’/g, "'")
            .replace(/‘/g, "`")
            .replace(/￥/g, "\\")
            .replace(/　/g, " ")
            .replace(/〜/g, "~");

  // カタカナを除去
  str = str.replace(/[\u{30A1}-\u{30F6}\u{30FB}-\u{30FE}]/mug, "")
            .replace(/[ｦ-ﾟ]/g, "");

  // ひらがなは除去
  str = str.replace(/[\u{3041}-\u{3093}\u{309B}-\u{309E}]/mug, "");

  // 漢字を除去
  // [一-龠]
  str = str.replace(/([\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)/mug, "");

  return str;
}

/**
 * 入力した文字列の小文字を大文字に変換する
 * 
 * @param {string} str - 変換したい文字列
 * @return {boolean} オンラインであれば「true」を返す
 */
export const toUpperCase = (str: string): string => {
    return str.toUpperCase();
}

/**
 * 入力されたカタカナを半角カタカナに変換する
 * 
 * @param {string} str - 変換したい文字列
 * @return {string} 変換したカナを返す
 */
export const toHalfKana = (str: string): string => {

  interface IkanaMap {
    [match: string]: string
  }
  // カタカナを半角カナへ（変換する場合はこちらを有効に）
  const kanaMap: IkanaMap = {
    "ガ": "ｶﾞ", "ギ": "ｷﾞ", "グ": "ｸﾞ", "ゲ": "ｹﾞ", "ゴ": "ｺﾞ",
    "ザ": "ｻﾞ", "ジ": "ｼﾞ", "ズ": "ｽﾞ", "ゼ": "ｾﾞ", "ゾ": "ｿﾞ",
    "ダ": "ﾀﾞ", "ヂ": "ﾁﾞ", "ヅ": "ﾂﾞ", "デ": "ﾃﾞ", "ド": "ﾄﾞ",
    "バ": "ﾊﾞ", "ビ": "ﾋﾞ", "ブ": "ﾌﾞ", "ベ": "ﾍﾞ", "ボ": "ﾎﾞ",
    "パ": "ﾊﾟ", "ピ": "ﾋﾟ", "プ": "ﾌﾟ", "ペ": "ﾍﾟ", "ポ": "ﾎﾟ",
    "ヴ": "ｳﾞ", "ヷ": "ﾜﾞ", "ヺ": "ｦﾞ",
    "ア": "ｱ", "イ": "ｲ", "ウ": "ｳ", "エ": "ｴ", "オ": "ｵ",
    "カ": "ｶ", "キ": "ｷ", "ク": "ｸ", "ケ": "ｹ", "コ": "ｺ",
    "サ": "ｻ", "シ": "ｼ", "ス": "ｽ", "セ": "ｾ", "ソ": "ｿ",
    "タ": "ﾀ", "チ": "ﾁ", "ツ": "ﾂ", "テ": "ﾃ", "ト": "ﾄ",
    "ナ": "ﾅ", "ニ": "ﾆ", "ヌ": "ﾇ", "ネ": "ﾈ", "ノ": "ﾉ",
    "ハ": "ﾊ", "ヒ": "ﾋ", "フ": "ﾌ", "ヘ": "ﾍ", "ホ": "ﾎ",
    "マ": "ﾏ", "ミ": "ﾐ", "ム": "ﾑ", "メ": "ﾒ", "モ": "ﾓ",
    "ヤ": "ﾔ", "ユ": "ﾕ", "ヨ": "ﾖ",
    "ラ": "ﾗ", "リ": "ﾘ", "ル": "ﾙ", "レ": "ﾚ", "ロ": "ﾛ",
    "ワ": "ﾜ", "ヲ": "ｦ", "ン": "ﾝ",
    "ァ": "ｧ", "ィ": "ｨ", "ゥ": "ｩ", "ェ": "ｪ", "ォ": "ｫ",
    "ッ": "ｯ", "ャ": "ｬ", "ュ": "ｭ", "ョ": "ｮ",
    "。": "｡", "、": "､", "ー": "ｰ", "「": "｢", "」": "｣", "・": "･"
  }
  const reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
  let _str = str.replace(reg, (match: string): string => {
                return kanaMap[match];
            })
            .replace(/゛/g, 'ﾞ')
            .replace(/゜/g, 'ﾟ')
            .replace(/　/g, ' '); // 全角スペースを半角へ
  return _str;
}

/**
 * 入力された文字列が半角ｶﾅのみかどうかチェック
 * ※半角スペースは許容する
 * 
 * @param {string} str - チェックしたい文字列
 * @return {boolean} 半角カナのみであれば「true」返す
 */
// export const isHalfKana = (str: string): boolean => {
//   // 半角カナ以外を含んでいたらfalseを返す
//   return /^[ｦ-ﾟ ]*$/.test(str);
// }

/**
 * UUIDを取得する
 * UUIDがローカルストレージに存在するかチェックし、
 * 存在する場合、ローカルストレージのUUIDを返す
 * 存在しなければ作成して保存した上でUUIDを返す
 * 
 * @return {string} UUID
 */
export const getUUID = (): string => {
  const keyName = 'uuid';
  let _uuid: string | null = localStorage.getItem(keyName);

  if (! _uuid) {
      // UUID 発行処理
      _uuid = uuidv1();

      // localStorageに保存
      localStorage.setItem(keyName, _uuid);
  }

  return _uuid;
}

/**
 * オブジェクトのデータをGETパラメータのクエリストリングに変換する
 * @module createQueryString
 * @param {object} params
 * @return {string} - key1=value1&key2=value2 という文字列
 */
  interface key {
    [index: string]: any;
  }
  export const createQueryString = (params: key): string => {
    return Object.keys(params).map((item) => {
      if(params){
        return item + "=" + params[item];
      }else {
        return ""
      }
    }).join('&');
}



/**
 * オブジェクトの配列をうけとって、指定のキー毎にデータをまとめる
 * @module createQueryString
 * @param {object[]}} data - オブジェクト型の配列
 * @return {string} key - グルーピングしたいキー
 * @return {object} - key を キー名としたオブジェクトを返す
 */
export const groupBy = (data: any[], key: string): any => {
  return data.reduce((prev, current, idx) => {
    // 戻テータのインデックスを保持
    let tmp = JSON.parse(JSON.stringify(current));
    tmp.defaultIndex = idx;
    (prev[current[key]] = prev[current[key]] || []).push(tmp);
    return prev;
  }, {});
}