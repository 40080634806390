import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SearchResult } from './searchResultsState'


export interface data {
  itemId: string,
  folderName: string,
  fileName: string,
  url: string,
  favorite: boolean
}

// stateの型
export interface SearchResultDetailState {
    searchResult: SearchResult,
    data: data[]
}

export const searchResultDetailState: SearchResultDetailState = {
    searchResult: {
      id: '',
      jikobango: '',
      syoukenNum: '',
      keiyakusyaName: '',
      syozaichiCode: '',
      specificDisasterCode: '',
      kakuteiFlug: false
    },
    data:[]
}

const SearchResultDetailStateModules = createSlice({
  name: 'searchResultDetailState',
  initialState: searchResultDetailState,
  reducers: {
    setSearchResult: (state, action: PayloadAction<SearchResult>) => ({
      ...state,
      searchResult: action.payload,
    }),
    setData: (state, action: PayloadAction<data[]>) => ({
      ...state,
      data: action.payload,
    }),
  },
})

export default SearchResultDetailStateModules
