import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

interface Props {
  children: React.ReactChild
  onClick?: () => void
  type?: 'primary' | 'secondary' | 'tertiary' | 'quaternary'
  size?: 'ss' | 'sm' | 'md' | 'lg'
  className?: string
  disabled?: boolean
}

const Button = ({ children, onClick, type = 'primary', size = 'md', className, disabled = false }: Props) => {
  return (
    <>
      <button
        onClick={onClick}
        className={clsx(
          styles.button,
          type === 'secondary'
            ? styles.secondary
            : type === 'tertiary'
            ? styles.tertiary
            : type === 'quaternary'
            ? styles.quaternary
            : styles.primary,
          size === 'sm' ? styles.sm : size === 'lg' ? styles.lg : size === 'ss' ? styles.ss : styles.md,
          className
        )}
        type="button"
        disabled={disabled}
      >
        {children}
      </button>
    </>
  )
}

export default Button
