import React, { useRef, useState } from 'react'
import styles from './styles.module.scss'
import { FormControl, makeStyles, TextField } from '@material-ui/core'

interface Props {
  name: string
  value: string
  setValue: (value: React.SetStateAction<string>) => void
  inputProps?: any
  placeholder?: string
  required?: boolean
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '320px',
    marginLeft: '15px'
  },
}))

const InputField = ({ name, value, setValue, inputProps, placeholder='入力してください', required = false }: Props) => {
  const classes = useStyles()
  const inputRef = useRef(null);
  const [inputError, setInputError] = useState(false);

  const changeInput = (event: React.ChangeEvent<{ value: string; }>) => {
    setValue(event.target.value)
    if (inputRef.current) {
      const ref: any = inputRef.current;
      if(ref) {
        if (!ref.validity.valid) {
          setInputError(true);
        } else {
          setInputError(false);
        }
      }
    }
  }

  const req = (required) ? " " + styles._required : '';

  return (
    <div className={styles.root}>
      <div className={styles.inputField}>
        <div className={styles['inputField-title'] + req}>{name}</div>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            variant="outlined"
            value={value}
            onChange={event=>changeInput(event)}
            inputProps={inputProps}
            placeholder={placeholder}
            inputRef={inputRef}
            error={inputError}
            helperText={inputError && inputProps.title}
          />
        </FormControl>
      </div>
    </div>
  )
}
export default InputField