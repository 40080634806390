import {
  combineReducers,
  configureStore,
  EnhancedStore,
} from '@reduxjs/toolkit'
import { History } from 'history'
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router'

// Import reducers
import searchResultsState, { SearchResultsState } from './searchResultsState';
import searchHistorysState, { SearchHistorysState } from './searchHistorysState';
import searchResultDetailState, { SearchResultDetailState } from './searchResultDetailState';
import hashState, { HashState } from './hashState';
import messageAreaState, { MessageAreaState } from './messageAreaState';
import settingsState, { SettingsState } from './settingsState';
import loginState, { LoginState } from './loginState';
import previousState, { PreviousState } from './previousState';
import searchFiltersState, { SearchFiltersState } from './searchFiltersState';


export interface RootState {
  router: RouterState,
  searchResultsState: SearchResultsState,
  searchHistorysState: SearchHistorysState,
  searchResultDetailState: SearchResultDetailState,
  hashState: HashState,
  messageAreaState: MessageAreaState,
  settingsState: SettingsState,
  loginState: LoginState,
  previousState: PreviousState,
  searchFiltersState: SearchFiltersState
}

// setup 関数を用意してエクスポートする。
//
export const setupStore = (history: History): EnhancedStore<RootState> => {
  const middlewares = [
    routerMiddleware(history),
  ]

  const store = configureStore({
    reducer: combineReducers({
      router: connectRouter(history),
      searchResultsState: searchResultsState.reducer,
      searchHistorysState: searchHistorysState.reducer,
      searchResultDetailState: searchResultDetailState.reducer,
      hashState: hashState.reducer,
      messageAreaState: messageAreaState.reducer,
      settingsState: settingsState.reducer,
      loginState: loginState.reducer,
      previousState: previousState.reducer,
      searchFiltersState: searchFiltersState.reducer
    }),
    middleware: middlewares,
  })

  return store
}
