import { createSlice, PayloadAction } from '@reduxjs/toolkit'


// stateの型
export interface PreviousState {
  isPrevious: boolean
}

// 初期値
export const previousState: PreviousState = {
  isPrevious: false
}

const PreviousStateModules = createSlice({
name: 'previousState',
initialState: previousState,
reducers: {
  setPrevious: (state, action: PayloadAction<PreviousState>) => ({
      ...state,
      ...action.payload,
  }),
},
})

export default PreviousStateModules
