import styles from '../Error/styles.module.scss'

// import Mui
import { Paper } from '@material-ui/core';



/* ******************************
 * Component
 */
const Offline = (): JSX.Element => {

  return (
    <div className={styles.Error}>
    <Paper className={styles['Error__base']}>
      <p className={styles['Error__title']}>
        一時保存対象ファイルが見つかりません。
      </p>
      <p className={styles['Error__main-text'] + " " + styles['_lft']}>
        入力したアカウントIDパスワードで当該端末でオフラインの一時保存機能を使用することが出来ません。
      </p>

      <Paper className={styles['Error__sub-base'] + " " + styles['_lft']}>
        <div>＜使用できない場合の例＞</div>
        <ul>
          <li>ID・パスワードが誤っている</li>
          <li>別端末用のID・パスワードを使用している</li>
          <li>一時保存していない・時間がたっている</li>
        </ul>
      </Paper>
    </Paper>
    </div>
  );
};

export default Offline;