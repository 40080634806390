import { createSlice, PayloadAction } from '@reduxjs/toolkit'
//import { data } from './searchResultDetailState'


export interface SearchHistory {
    id: string
    jikobango: string
    syoukenNum: string
    keiyakusyaName: string
    syozaichiCode: string
    specificDisasterCode: string
    searchdate: Date
    //kakuteiFlug: boolean
}

// stateの型
export interface SearchHistorysState {
    searchHistorys: SearchHistory[],
}

export const searchHistorysState: SearchHistorysState = {
    searchHistorys: [],
}

const SearchHistoryStateModules = createSlice({
  name: 'SearchHistorysState',
  initialState: searchHistorysState,
  reducers: {
    setSearchHistorys: (state, action: PayloadAction<SearchHistory[]>) => ({
        ...state,
        searchHistorys: action.payload,
    }),
  },
})

export default SearchHistoryStateModules
