import { useState, useEffect } from "react";
import styles from '../ImageView/styles.module.scss';
import heic2any from 'heic2any'

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
  dataURI: string | undefined
}

const HeicView = ({dataURI}: Props) => {
  // loader
  const [open, setOpen] = useState(false);

  const [image, setImage] = useState<JSX.Element>(<></>);

  useEffect(() => {
    if (dataURI) {
      setOpen(true);
      fetch(dataURI)
        .then((res) => {
          return res.blob();
        })
        .then((res) => {
          // console.log("working on", res);
          return heic2any({ blob: res, toType: "image/png", quality: 0.5 });
        })
        .then((res) => {
          // console.log("return heic2any is ", res);
          /* @ts-ignore */
          const url: string = URL.createObjectURL(res);
          setImage(<img src={url} alt="" />);
        })
        .then(() => {
          setOpen(false);
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      <div className={styles.ImageWrapper}>
        {image}
      </div>
      <Backdrop open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
export default HeicView
