import styles from './styles.module.scss'

interface Props {
  dataURI: string | undefined
}

const ImageView = ({dataURI}: Props) => {

  // console.log('dataURI is ', dataURI)

  return (
    <div className={styles.ImageWrapper}>
        <img src={dataURI} alt="" />
    </div>
  );
}
export default ImageView
