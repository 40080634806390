import { createSlice, PayloadAction } from '@reduxjs/toolkit'


// stateの型
export interface HashState {
    id: string
    hash: string
}

export const hashState: HashState = {
    id: '',
    hash: ''
}

const HashStateModules = createSlice({
  name: 'hashState',
  initialState: hashState,
  reducers: {
    setHash: (state, action: PayloadAction<HashState>) => ({
        ...state,
        ...action.payload,
    }),
  },
})

export default HashStateModules
