import React, { useState } from "react";
import styles from './styles.module.scss'
import { Page, Document } from "react-pdf/dist/esm/entry.webpack5";

interface Props {
  dataURI: string | undefined
}

const PdfView = React.memo(({dataURI}: Props) => {
  const [totalPages, setTotalPages] = useState(0);

  const onLoadSuccess = (numPages: number)=>{
    // console.log("PDF成功")
    setTotalPages(numPages);
  }

  /**
   * 1ページずつ表示する
   */
  const pdfContents = [];
  for(let i = 0; i < totalPages; i++) {
    // console.log(i)
    const d = (
      <div key={'total' + i}>
        <div>ページ{i + 1}/{totalPages}</div>
        <Page pageNumber={i+1}/>
      </div>
    );
    pdfContents.push(d);
  }

  return (
    <div style={
      {
        width: "100%",
        height: "100%",
        overflow: 'scroll',
        paddingTop: '32px'
      }}
      className={styles.pdf}
    >
        <Document
            // file='https://mobility-iot-portal-dev.sompo-japan.co.jp/kensho_data/07_testpdf1.pdf'
            file={dataURI}
            onLoadSuccess={(numPages) => onLoadSuccess(numPages.numPages)}
        >
            {pdfContents}
        </Document>
    </div>
  );
});

export default PdfView
