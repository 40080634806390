import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

interface Props {
  onClose?: () => void
  children: React.ReactNode
  isActive: boolean
  size?: 'sm' | 'md' | 'lg'
  className?: string
  isCloseButton?: boolean
}
const Modal = ({ onClose, children, isActive, size = 'md', className, isCloseButton }: Props) => {
  return (
    <>
      <div className={clsx(styles.root, isActive && styles['is-active'])}>
        <div
          className={clsx(
            styles.contents,
            className,
            size === 'sm' ? styles.sm : size === 'lg' ? styles.lg : styles.md
          )}
        >
          {children}
          {isCloseButton && <span className={styles['close-btn']} onClick={onClose}></span>}
        </div>
        <div className={styles.overlay} onClick={onClose}></div>
      </div>
    </>
  )
}

export default Modal
