import { createSlice, PayloadAction } from '@reduxjs/toolkit'


export interface SearchResult {
    id: string
    jikobango: string
    syoukenNum: string
    keiyakusyaName: string
    syozaichiCode: string
    specificDisasterCode: string
    kakuteiFlug: boolean
}

// stateの型
export interface SearchResultsState {
    searchResults: SearchResult[],
}

export const searchResultsState: SearchResultsState = {
    searchResults: [],
}

const SearchResultStateModules = createSlice({
  name: 'searchResultsState',
  initialState: searchResultsState,
  reducers: {
    setSearchResults: (state, action: PayloadAction<SearchResult[]>) => ({
        ...state,
        searchResults: action.payload,
    }),
  },
})

export default SearchResultStateModules
