import React from 'react'
import styles from './styles.module.scss'
import AppBar from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/core'
import Button from '../../atoms/Button'


const useStyles = makeStyles((theme) => ({
  footer: {
    padding: '10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#666'
  },
}))

interface Props {
  onClick?: () => void
}

const ModalFooter = React.memo(({onClick}: Props) => {
  const classes = useStyles()

  return (
    <div className={styles.root}>
      <div className={styles.modalfooter}>
        <AppBar position="static" className={classes.footer}>
          <div className={styles['footer-button']}><Button type={"secondary"} size={"md"} onClick={onClick}>閉じる</Button></div>
        </AppBar>
      </div>
    </div>
  )
});

export default ModalFooter