import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.scss'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core'
import Button from '../../atoms/Button'
import { push } from 'connected-react-router'
import clsx from 'clsx'
import { selectLoginState,selectPreviousState } from '../../../store/selector'
import SettingsStateModules from '../../../store/settingsState';
import LoginStateModules from '../../../store/loginState';
import PreviousStateModules from '../../../store/previousState';

import { isOnline } from '../../../util/utils'

const useStyles = makeStyles((theme) => ({
  footer: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#666666',
    minHeight: '65px',
  },
  bar: {
    padding: '0 12px',
    minHeight: '45px'
  }
}))


const Footer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const location = useLocation();
  let button: JSX.Element = (<></>);
  let disp = false

  // settincsState を更新
  const setSettingState = useCallback(
    values => dispatch(SettingsStateModules.actions.setSettings({ ...values })),
    [dispatch]
  )
  // loginState を更新
  const setLoginState = useCallback(
    values => dispatch(LoginStateModules.actions.setLogin({ ...values })),
    [dispatch]
  )

  const setPreviousState = useCallback(
    values => dispatch(PreviousStateModules.actions.setPrevious({ ...values })),
    [dispatch]
  )

  const logout = () => {
    dispatch(setSettingState({ IdToken: '' }));
    dispatch(setLoginState({ isLoggedIn: false }));
    dispatch(setPreviousState({ isPrevious: false }));
    dispatch(push('/login'))
  }

  const onClickMoveLoginButton = () => {
    logout();
  }
  const onClickMoveSearchButton = () => {
    if (isOnline()) { // 利用中にオフラインになった場合を考慮
      dispatch(push('/'))
    } else {
      logout();
    }
  }
  const onClickMoveResultsButton = () => {
    if (isOnline()) { // 利用中にオフラインになった場合を考慮
      dispatch(push('/results'))
    } else {
      logout();
    }
  }

  const onClickMoveHistoryButton = () => {
    if (isOnline()) { // 利用中にオフラインになった場合を考慮
      dispatch(push('/searchhistory'))
    } else {
      logout();
    }
  }

  // isLoggedIn を取得
  const { isLoggedIn } = useSelector(selectLoginState)
  // console.log(isLoggedIn)

  const { isPrevious } = useSelector(selectPreviousState)

  switch (location.pathname) {
    case '/results':
      button = (<Button type="secondary" onClick={()=>onClickMoveSearchButton()}>検索画面に戻る</Button>);
      disp = true
      break;
    case '/searchhistory':
      button = (<Button type="secondary" onClick={()=>onClickMoveSearchButton()}>検索画面に戻る</Button>);
      disp = true
      break;
    case '/resultDetail':
      if(isPrevious){
        button = (<Button type="secondary" onClick={()=>onClickMoveHistoryButton()}>検索履歴画面に戻る</Button>);
        disp = true
        break;        
      }else{
        button = (<Button type="secondary" onClick={()=>onClickMoveResultsButton()}>検索結果一覧画面に戻る</Button>);
        disp = true
        break;
      }
    case '/list':
      if(isLoggedIn && isOnline()) {
        button = (<Button type="secondary" onClick={()=>onClickMoveSearchButton()}>検索画面に戻る</Button>);
      }else {
        button = (<Button type="secondary" onClick={()=>onClickMoveLoginButton()}>ログイン画面に戻る</Button>);
      }
      disp = true
      break;
    case '/offline':
      button = (<Button type="secondary" onClick={()=>onClickMoveLoginButton()}>ログイン画面に戻る</Button>);
      disp = true
      break;
  } 

  return (
    <div className={clsx(styles['root'], !disp && styles['disp-none'])}>
      <div className={styles.footer}>
        <AppBar position="static" className={classes.footer}>
          <Toolbar className={classes.bar}>
            {button}
          </Toolbar>
        </AppBar>
      </div>
    </div>
  )
}
export default Footer
