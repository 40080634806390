// import React from 'react'
import styles from './styles.module.scss'
import AppBar from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/core'
import Button from '../../atoms/Button'

import { PastCaseData } from '../../../localdb/db'

const useStyles = makeStyles((theme) => ({
  header: {
    padding: '10px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff'
  },
}))

interface Props {
  onClick?: () => void
  fileData: PastCaseData | undefined
}

// const ModalHeader = React.memo(({onClick, fileData}: Props) => {
const ModalHeader = ({onClick, fileData}: Props) => {
  const classes = useStyles()

  const isFavorite = fileData?.favorite;

  return (
    <div className={styles.root}>
      <div className={styles.modalheader}>
        <AppBar position="static" className={classes.header}>
          <div className={styles.currentFavorite + (isFavorite ? ' ' + styles.currentFavorite_on : '')} onClick={onClick}></div>
          <div className={styles['header-button']}><Button type={"secondary"} size={"md"} onClick={onClick}>{isFavorite ? 'お気に入り解除する' : 'お気に入り登録する'}</Button></div>
        </AppBar>
      </div>
    </div>
  )
};
// }, (prevProps: Props, nextProps: Props) => {
//   console.log(prevProps.fileData?.favorite, nextProps.fileData?.favorite);
//   const prevFavState = prevProps.fileData?.favorite;
//   const nextFavState = nextProps.fileData?.favorite;
//   return (prevFavState === nextFavState);
// });

export default ModalHeader