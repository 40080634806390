import { createSlice, PayloadAction } from '@reduxjs/toolkit'


// stateの型
export interface SettingsState {
    IdToken: string
    RefreshToken: string
}

export const settingsState: SettingsState = {
    IdToken: '',
    RefreshToken: ''
}

const SettingsStateModules = createSlice({
  name: 'settingsState',
  initialState: settingsState,
  reducers: {
    setSettings: (state, action: PayloadAction<SettingsState>) => ({
        ...state,
        ...action.payload,
    }),
  },
})

export default SettingsStateModules
