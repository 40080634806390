import { useCallback, useState } from 'react'
import Axios from '../../apis/axios';
import styles from './styles.module.scss'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/atoms/Button'
import InputField from '../../components/atoms/Input/InputField'
import SompoJapanLogo from '../../images/sompojapan.svg'
import { isOnline, toUpperCase, toHalfWidth, toHalfKana } from '../../util/utils'
import SearchResultStateModules, { SearchResult } from '../../store/searchResultsState'
import SearchHistoryStateModules, { SearchHistory } from '../../store/searchHistorysState'
import SearchFiltersStateModules from '../../store/searchFiltersState'
import SettingsStateModules from '../../store/settingsState';
import LoginStateModules from '../../store/loginState';
import PreviousStateModules from '../../store/previousState';
import { selectSettingsState, selectSearchFiltersState } from '../../store/selector';
import { getErrorMessages } from '../../util/messageUtil';
import { Messages } from '../../config/messages';
import { makeStyles } from '@material-ui/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


/* ******************************
 * STYLES
 */
const useStyles = makeStyles({
  backdrop: {
    zIndex: 2,
    color: '#fff',
  }
});


const Search = () => {
  const classes = useStyles();

  // const xApiKey = process.env.REACT_APP_API_KEY
  const idToken = useSelector(selectSettingsState)

  // 検索条件を取得（storeにあれば初期値としてセット）
  const searchFilters = useSelector(selectSearchFiltersState)

  // -------------------------------------
  const [syoukenNum, setSyoukenNum] = useState(searchFilters.syoukenNum);
  const [keiyakuName, setKeiyakuName] = useState(searchFilters.keiyakuName);
  const [syozaichiCode, setSyozaichiCode] = useState(searchFilters.syozaichiCode);

  // loader
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch()
  // actionの呼出
  // 検索条件を保存
  const setSearchFilters = useCallback(
    values => dispatch(SearchFiltersStateModules.actions.setSearchFilters(values)),
    [dispatch]
  )
    // 検索結果を保存
  const setSearchResults = useCallback(
    values => dispatch(SearchResultStateModules.actions.setSearchResults(values)),
    [dispatch]
  )

  // 検索履歴を保存
  const setSearchHistorys = useCallback(
    values => dispatch(SearchHistoryStateModules.actions.setSearchHistorys(values)),
    [dispatch]
  )

  // loginState を更新
  const setPreviousState = useCallback(
    values => dispatch(PreviousStateModules.actions.setPrevious({ ...values })),
    [dispatch]
  )

  // settincsState を更新
  const setSettingState = useCallback(
    values => dispatch(SettingsStateModules.actions.setSettings({ ...values })),
    [dispatch]
  )
  // loginState を更新
  const setLoginState = useCallback(
    values => dispatch(LoginStateModules.actions.setLogin({ ...values })),
    [dispatch]
  )

  // 変換前の入力内容を保持
    let tmpInputData = {
      syoukenNum: '',
      keiyakuName: '',
      syozaichiCode: ''
    };


  const onClickSearchButton = () => {

    // 入力内容保持のために変換前のデータを取得。検索成功したらStoreに保存
    tmpInputData = {
      syoukenNum: syoukenNum,
      keiyakuName: keiyakuName,
      syozaichiCode: syozaichiCode
    };

    // 入力値の変換
    const _syoukenNum = toHalfWidth(toUpperCase(syoukenNum));
    const _keiyakuName = toHalfKana(keiyakuName);

    // console.log(_syoukenNum, _keiyakuName, syozaichiCode);

    let errors = [];
    // 証券番号と契約者名共にからの場合はエラー
    if (_syoukenNum === '' && _keiyakuName === '') {
      errors.push('● 証券番号または契約者名を入力してください');
    }

    // 所在地コードがあるのに契約者名が空だった場合エラー
    // 所在地コードは契約者名とセットじゃないとダメ
    if (syozaichiCode !== '' && _keiyakuName === '') {
      errors.push('● 所在地コードで検索する場合は、契約者名も入力してください');
    }

    // inputのonChangeでバリデーションを行っているが、
    // Submitしたときにバリデーションエラーが発生していても送信されてしまう。
    // そのためSubmit時にもバリデーションを行い、バリデーションエラーの時は送信処理を行わない。
    var syokenNumRegex = new RegExp(/^[0-9A-Za-z]+$/);
    var keiyakusyaNameRegex = new RegExp(/^[\u30a1-\u30fe\u3000\uFF66-\uFF9F ]+$/);
    var syozaichiCodeRegex = new RegExp(/^[0-9A-Za-z]+$/);

    // オンラインならAPI叩いてログイン処理。通常のオンラインフローへ
    if (
      (syokenNumRegex.test(syoukenNum) || syoukenNum === '')
      && (keiyakusyaNameRegex.test(keiyakuName) || keiyakuName === '')
      && (syozaichiCodeRegex.test(syozaichiCode) || syozaichiCode === '')
    ) {
      if (errors.length === 0) {
        getSearchResult(_syoukenNum, _keiyakuName, syozaichiCode)
      } else {
        alert(errors.join("\n"));
      }
    }
  }

  // 検索APIを呼出
  const getSearchResult = async (syoukenNum: string, keiyakusyaName: string, syozaichiCode: string) => {
    // オフラインの場合ログイン画面へ飛ばす
    if (!isOnline()) {
      // logout
      dispatch(setSettingState({ IdToken: '' }));
      dispatch(setLoginState({ isLoggedIn: false })); 
      dispatch(push('/login'));
      return;
    }
    // if(xApiKey) {
      setOpen(true);
      
      await Axios
      ({
        method: 'get',
        url: '/get_past_accidents?syoken_num=' + syoukenNum + '&keiyakusya_name=' + keiyakusyaName + '&syozaichi_cd=' + syozaichiCode,
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken.IdToken,
          // 'x-api-key': xApiKey
        }
      })
      .then(res => {
        // 業務エラー
        let messages = [];
        if (res.data.error_type) {
          messages = getErrorMessages(res.data, 'search');
          if (messages) {
            alert(messages.join("\n"));
          }
        }else {
          // SyntaxError: Unexpected token o in JSON at position 1 エラーを回避するために先にstringifyしてからparseする
          const jsonData = JSON.parse(JSON.stringify(res.data.data))
          const searchResults: SearchResult[] = []
          jsonData.map((data: any) => {
            const searchResult = {
              id: data.past_accident_id,
              jikobango: data.accident_number,
              syoukenNum: data.syoken_num,
              keiyakusyaName: data.keiyakusya_name,
              syozaichiCode: data.syozaichi_cd,
              specificDisasterCode: data.specific_disaster_code,
              kakuteiFlug:  data.koho_flg === "1" || false
            }
            return searchResults.push(searchResult)
          })
          // 検索条件をstoreに保存
          setSearchFilters(tmpInputData);

          // 検索結果をstoreに保存
          setSearchResults(searchResults);
          dispatch(setPreviousState({ isPrevious: false }));
          dispatch(push("results"))
        }
        setOpen(false);
      })
      .catch(error => {
        setOpen(false);
        console.log(error, error.response);
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          if (error.response.status === 401) {
            alert(Messages.api.status401.message);
          } else if (error.response.status === 403) {
            alert(error.response.data.message);
          }
          // logout
          dispatch(setSettingState({ IdToken: '' }));
          dispatch(setLoginState({ isLoggedIn: false })); 
          dispatch(push('/login'));
        } else if (error.response && (error.response.status === 502 || error.response.status === 504)) {
          alert(Messages.api.status502.message);
        } else {
          dispatch(push('/error'));
        }
      });
    // }
  }

  const onClickSearchHistoryButton = () => {
    // オンラインならAPI叩いてログイン処理。通常のオンラインフローへ
    getSearchHistoryResult()
  }

  // 検索履歴APIを呼出
  const getSearchHistoryResult = async () => {
    // オフラインの場合ログイン画面へ飛ばす
    if (!isOnline()) {
      // logout
      dispatch(setSettingState({ IdToken: '' }));
      dispatch(setLoginState({ isLoggedIn: false })); 
      dispatch(push('/login'));
      return;
    }
    // if(xApiKey) {
      setOpen(true);
      
      await Axios
      ({
        method: 'get',
        url: '/get_search_history',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken.IdToken,
          // 'x-api-key': xApiKey
        }
      })
      .then(res => {
        // 業務エラー
        let messages = [];
        if (res.data.error_type) {
          messages = getErrorMessages(res.data, 'searchhistory');
          if (messages) {
            alert(messages.join("\n"));
          }
        }else {
          // SyntaxError: Unexpected token o in JSON at position 1 エラーを回避するために先にstringifyしてからparseする
          const jsonData = JSON.parse(JSON.stringify(res.data.data))
          const searchHistoryResults: SearchHistory[] = []
          jsonData.map((data: any) => {
            const searchHistory = {
              id: data.past_accident_id,
              jikobango: data.accident_number,
              syoukenNum: data.syoken_num,
              keiyakusyaName: data.keiyakusya_name,
              syozaichiCode: data.syozaichi_cd,
              specificDisasterCode: data.specific_disaster_code,
              searchdate: data.search_date
              //kakuteiFlug:  data.koho_flg === "1" || false
            }
            return searchHistoryResults.push(searchHistory)
          })

          // 検索結果をstoreに保存
          setSearchHistorys(searchHistoryResults);
          //setSearchResults(searchHistoryResults);
          dispatch(setPreviousState({ isPrevious: true }));          
          dispatch(push("/searchhistory"))
        }
        setOpen(false);
      })
      .catch(error => {
        setOpen(false);
        console.log(error, error.response);
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          if (error.response.status === 401) {
            alert(Messages.api.status401.message);
          } else if (error.response.status === 403) {
            alert(error.response.data.message);
          }
          // logout
          dispatch(setSettingState({ IdToken: '' }));
          dispatch(setLoginState({ isLoggedIn: false })); 
          dispatch(push('/login'));
        } else if (error.response && (error.response.status === 502 || error.response.status === 504)) {
          alert(Messages.api.status502.message);
        } else {
          dispatch(push('/error'));
        }
      });
    // }
  }

  return (
    <div className={styles.Search}>
      <div className={styles.Search__inner}>
        <div className={styles.Search__logo}>
          <img src={SompoJapanLogo} alt="Logo" />
        </div>
        <div className={styles.Search__form}>
          <div className={styles.Search__stage}>
            <div className={styles.Search__required}>
              <div className={styles['Search__required-text']}>いずれかひとつは必ず入力してください</div>
              <div className={styles['Search__wrap']}>
                <div className={styles['Search__input']}>
                  <InputField name="証券番号" value={syoukenNum} setValue={setSyoukenNum} required={true} inputProps={{inputMode: 'url', pattern: '^[0-9A-Za-z]+$', title: '半角英数字で入力してください' }} placeholder='M以降から入力'/>
                </div>
                <div className={styles['Search__input']}>
                  <InputField name="契約者名(カナ)" value={keiyakuName} setValue={setKeiyakuName} required={true} inputProps={{pattern: '^[\u30a1-\u30fe\u3000\uFF66-\uFF9F ]+$', title: 'カタカナで入力してください' }} />
                </div>
              </div>
            </div>
            <div className={styles['Search__not-required']}>
              <div className={styles['Search__wrap']}>
                <div className={styles['Search__input']}>
                  <InputField name="所在地コード" value={syozaichiCode} setValue={setSyozaichiCode} inputProps={{inputMode: 'url', pattern: '^[0-9A-Za-z]+$', title: '半角英数字で入力してください' }} />
                </div>
              </div>
            </div>
            <div className={styles['Search__border-dashed']}></div>
            <div className={styles.Search__submit}>
            <Button size={"lg"} onClick={() => onClickSearchButton()}>検索する</Button>
            </div>
          </div>
          <div className={styles.Search__history_stage}>
            <div className={styles['Search__history-required']}>
              <div className={styles['Search__history-text']}>過去3日間の検索履歴はこちらから</div>
            </div>
            <div className={styles['Search__history']}>
            <Button className={styles['search-history']}size={"lg"} onClick={() => onClickSearchHistoryButton() }>検索履歴</Button>
            </div>
          </div>
        </div>
      </div>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}
export default Search
