// import React / Redux
import React from 'react';

// import Components
import TextLabel from '../../atoms/TextLabel';

// import SASS
import styles from './styles.module.scss'


/* ******************************
 * TYPES
 */
interface TextLabelItemProps {
  label: string | Array<string | JSX.Element>
  children: React.ReactNode
  variant?: "large"
}



/* ******************************
 * Component
 */
const TextLabelItem = (props: TextLabelItemProps): JSX.Element => {

  return (
    <div className={styles.TextLabelItem}>
      <TextLabel variant={props.variant}>{props.label}</TextLabel><span>{props.children}</span>
    </div>
  );
};

export default TextLabelItem;