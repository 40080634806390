import { createSlice, PayloadAction } from '@reduxjs/toolkit'


// stateの型
export interface LoginState {
  isLoggedIn: boolean
}

// 初期値
export const loginState: LoginState = {
  isLoggedIn: false
}

const LoginStateModules = createSlice({
name: 'loginState',
initialState: loginState,
reducers: {
  setLogin: (state, action: PayloadAction<LoginState>) => ({
      ...state,
      ...action.payload,
  }),
},
})

export default LoginStateModules
