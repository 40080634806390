import React from 'react';
import './App.scss';
// routing
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

// store
import { setupStore } from './store'
import { Provider } from 'react-redux'

// components
import Auth from './Auth';
import Login from './pages/Login';
import Error from './pages/Error';
import Offline from './pages/Offline';
import Search from './pages/Search';
import Searchhistory from './pages/Searchhistory';
import Results from './pages/Results';
import ResultDetail from './pages/ResultDetail';
import TemporarySaveList from './pages/TemporarySaveList';
import Header from './components/molecules/Header';

import { deleteRecentData } from './localdb/dbUtil/pastCases';

import { isOnline } from './util/utils';
import './scss/common.scss'
import Footer from './components/molecules/Footer';

const history = createBrowserHistory()

// store設定
const store = setupStore(history)

const App: React.FC = () => {

  const checkUpdate = () => {
    // https://github.com/rahuls360/testing-sw/blob/master/src/App.js
    // https://qiita.com/wakeupsloth/items/6778df1e984f55c446e9
    // etc.
    navigator.serviceWorker.getRegistrations()
      .then((regs) => {
        regs.forEach((reg) => {
          reg.update().then(() => {
            reg.waiting?.postMessage({ type: 'SKIP_WAITING' });
            if (reg.waiting) {
              window.location.reload();
            }
          })
        })
      });
  }

  React.useEffect(() => {
    // ページ切り替わりの度にオフラインチェックする
    history.listen((location) => {
      if (!isOnline() && (location.pathname === '/' || location.pathname === '/results' || location.pathname === '/resultDetail')) {
        // オフラインで且つ一時保存一覧画面以外ならログインへリダイレクト
        history.push('/login')
      }

      // ログイン画面では service worker の updateチェックする。
      if (location.pathname === '/login') {
        checkUpdate();
      }
    });

    // アプリ立ち上げ時アップデートチェック
      checkUpdate();
  });

    // 起動時1回だけ実行
  React.useEffect(() => {
    // DB容量いっぱいでDBがcloseしてしまう事態になった場合、localStorageに
    // DB_QUOTA_EXCEEDEDが登録されるので、一番新しく保存された事案データを削除する
    const quotaExceeded = localStorage.getItem('DB_QUOTA_EXCEEDED');
    if (quotaExceeded) {
      deleteRecentData()
      .then(() => {
        console.log('Delete recent data!');
        localStorage.removeItem('DB_QUOTA_EXCEEDED');
      })
      .catch((error) => {
        console.log('Delete recent data faild.');
      });
    }

    // 現状のストレージ容量について把握（使用量と使用可能量）
    // https://web.dev/i18n/ja/storage-for-the-web/#check
    // (async () => {
    //   if (navigator.storage && navigator.storage.estimate) {
    //     const quota = await navigator.storage.estimate();
    //     // quota.usage -> Number of bytes used.
    //     // quota.quota -> Maximum number of bytes available.
    //     // @ts-ignore
    //     const percentageUsed = (quota.usage / quota.quota) * 100;
    //     console.log(`You've used ${percentageUsed}% of the available storage.`);
    //     // @ts-ignore
    //     const remaining = quota.quota - quota.usage;
    //     console.log(`You can write up to ${remaining} more bytes.`);
    //   }
    // })();
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Header/>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/error" component={Error} />
            <Route exact path="/offline" component={Offline} />

            <Auth>
              <Route exact path="/" component={Search} />
              <Route exact path="/list" component={TemporarySaveList} />
              <Route exact path="/results" component={Results} />
              <Route exact path="/searchhistory" component={Searchhistory} />
              <Route exact path="/resultDetail(/:jikobangou)?" component={ResultDetail} />
            </Auth>
          </Switch>
          <Footer/>
        </ConnectedRouter>
      </Provider>
    </div>
  )
}

export default App