import React from 'react'
import styles from './styles.module.scss'
import PdfView from '../../atoms/File/PdfView'
import ImageView from '../../atoms/File/ImageView'
import HeicView from '../../atoms/File/HeicView'
// import TiffTest from '../../test/TiffTest'
import ModalHeader from '../../molecules/ModalHeader'
import ModalFooter from '../../molecules/ModalFooter'

import { PastCaseData } from '../../../localdb/db'

interface Props {
  fileData: PastCaseData | undefined
  onClick?: () => void
  onToggleFavorite: () => void
}

const ModalData = React.memo(({fileData, onClick, onToggleFavorite}: Props) => {

  // ファイルのパスを保持（Blobの場合はDataURIを保持）
  // const [thumb, setThumb] = useState<string>();
  let thumb: string = '';

  let jsx: JSX.Element = (<></>);
  let fileType: string | undefined = '';

  // オンラインだと fileData.url に ファイルのパスが
  // オフラインだと fileData.base64 に ファイルのbase64データが入ってくる
  if (fileData?.hasOwnProperty('base64') && fileData.base64) {
    // 一時保存一覧から開かれる場合はBlobを受け取る

    fileType = fileData.type?.toLowerCase();
    // const tmpFile = new File([fileData.blob], `img.${fileData.type}`);

    /* Blob url scheme */
    // thumb = window.URL.createObjectURL(tmpFile);
    thumb = fileData.base64 || '';

  } else if (fileData?.hasOwnProperty('url')) {
    // 検索結果詳細画面から開かれる場合URLを受け取る
    fileType = fileData.url?.split('.').pop()?.split('?')[0].toLowerCase();
    // setThumb(fileData.url?.toString())
    thumb = fileData.url || '';
  }

  if (fileType?.includes('png') || fileType?.includes('jpg') || fileType?.includes('jpeg') || fileType?.includes('gif')) {
    jsx = (<ImageView dataURI={thumb} />)
  } else if (fileType?.includes('pdf')) {
    jsx = (<PdfView dataURI={thumb} />)
  } else if (fileType?.includes('heic') || fileType?.includes('binary')) {
    jsx = (<HeicView dataURI={thumb} />)
  }

  return (
    <div className={styles.root}>
      <ModalHeader onClick={onToggleFavorite} fileData={fileData} />
      <div className={styles.modaldata}>
        {jsx}
      </div>
      <ModalFooter onClick={onClick} />
    </div>
  )
});

export default ModalData
