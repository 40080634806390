import { RootState } from '../store'
import { HashState } from './hashState'
import { MessageAreaState } from './messageAreaState'
import { SearchFiltersState } from './searchFiltersState'
import { SearchResultDetailState } from './searchResultDetailState'
import { SearchResultsState } from './searchResultsState'
import { SearchHistorysState } from './searchHistorysState'
import { SettingsState } from './settingsState'
import { LoginState } from './loginState'
import { PreviousState } from './previousState'

export const selectSearchResultState = (store: RootState): SearchResultsState => store.searchResultsState
export const selectSearchHistoryState = (store: RootState): SearchHistorysState => store.searchHistorysState
export const selectSearchResulDetailState = (store: RootState): SearchResultDetailState => store.searchResultDetailState
export const selectHashState = (store: RootState): HashState => store.hashState
export const selectMessageAreaState = (store: RootState): MessageAreaState => store.messageAreaState
export const selectSettingsState = (store: RootState): SettingsState => store.settingsState
export const selectLoginState = (store: RootState): LoginState => store.loginState
export const selectPreviousState = (store: RootState): PreviousState => store.previousState
export const selectSearchFiltersState = (store: RootState): SearchFiltersState => store.searchFiltersState

// router
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectRouter = (store: RootState): any => store.router