import styles from './styles.module.scss'
import clsx from 'clsx'
import Button from '../Button'
import { PastCaseData } from '../../../localdb/db'

interface Props {
  fileData?: PastCaseData
  type?: 'image' | 'pdf'
  size?: 'sm' | 'md' | 'lg'
  className?: string
  openModal: () => void
  onToggleFavorite: () => void
}

const DataIcon = ({ fileData, type = 'image', openModal, onToggleFavorite }: Props) => {
  return (
    <div className={clsx(styles['data-icon'], fileData?.favorite && styles['data-icon-color'])}>
      <div className={styles['data-icon-header-wrapper']}>
        <div className={styles['data-icon-header']}>
          <div className={styles['data-icon-header-type']}>{type === 'pdf' ? 'PDF' : '画像'}</div>
          <div className={styles['data-icon-header-file']}>{fileData?.fileName}</div>
        </div>
        <div className={clsx(styles['data-icon-fav'], fileData?.favorite && styles['data-icon-fav-color'])} onClick={onToggleFavorite}></div>
      </div>
      <div className={styles['data-icon-button']}>
        <Button type={"secondary"} size={"sm"} onClick={openModal}><span>ファイルを<br/>表示する</span></Button>
        <Button type={"secondary"} size={"sm"} onClick={onToggleFavorite} className={fileData?.favorite ?  styles['data-icon-button-fav'] : ''}><span>お気に入り<br/>{fileData?.favorite ?'解除する':'登録する'}</span></Button>
      </div>
    </div>
  )
}

export default DataIcon
